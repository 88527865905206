import React, { Component } from 'react';
import { Popup } from 'react-map-gl';
import moment from 'moment';

class SitePopup extends Component {
	constructor() {
		super();
		this.state = {
			selectedId: ''
		};
	}

	componentDidMount() {
		const { sites } = this.props;
		this.setState({ selectedId: sites[0].id });
	}

	componentDidUpdate(_, { selectedId: prevSelectedId }) {
		const { selectedId } = this.state;

		if (selectedId !== prevSelectedId) {
			this.props.setActivePopup(selectedId);
		}
	}

	render() {
		const { sites, closePopup, updatedLngLat } = this.props;
		// console.log('updatedLngLat', updatedLngLat);
		const { selectedId } = this.state;
		if (!sites.length) return null;
		// console.log('sites', sites);
		const canNavigate = sites.length > 1 && selectedId !== '';
		const { name, company, capacity, captured, pType, lat, lon } =
			sites.length === 1 || !canNavigate
				? sites[0]
				: sites.find(({ id }) => id === this.state.selectedId);
		const selectedIndex =
			sites.length === 1 || !canNavigate
				? 0
				: sites.findIndex(({ id }) => id === this.state.selectedId);

		return (
			<Popup
				offset={15}
				latitude={updatedLngLat?.lat ? updatedLngLat.lat : lat}
				longitude={updatedLngLat?.lng ? updatedLngLat.lng : lon}
				onClose={closePopup}
				closeButton={false}
				closeOnClick={true}
				closeOnMove={true}
			>
				<div className="card">
					<h6 className="card-header">{name}</h6>
					<div className="card-body">
						<p className="card-text mb-0">Company: {company}</p>
						<p className="card-text mb-0">Commodity: steel</p>
						<p className="card-text mb-0">Type: {pType}</p>
						<p className="card-text mb-0">Capacity: {capacity} kt</p>
						<p className="card-text">
							Capture:{' '}
							{captured ? (
								moment.utc(captured).format('Do MMMM YYYY')
							) : (
								<em>Unknown</em>
							)}
						</p>

						{canNavigate && (
							<div className="text-center">
								<div
									className="btn-group"
									role="group"
									aria-label="Sites info navigation"
								>
									<button
										type="button"
										className={`btn btn-outline-secondary btn-sm ${
											selectedIndex === 0 ? 'disabled' : ''
										}`}
										style={{ boxShadow: 'none' }}
										onClick={() =>
											this.setState({
												selectedId: sites[selectedIndex - 1].id
											})
										}
									>
										Previous
									</button>
									<button
										type="button"
										className={`btn btn-outline-secondary btn-sm ${
											selectedIndex === sites.length - 1 ? 'disabled' : ''
										}`}
										style={{ boxShadow: 'none' }}
										onClick={() =>
											this.setState({
												selectedId: sites[selectedIndex + 1].id
											})
										}
									>
										Next
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			</Popup>
		);
	}
}

export default SitePopup;
