import 'font-awesome/css/font-awesome.min.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';
import { ConnectedFlagsProvider } from 'flag';
import ReduxToastr from 'react-redux-toastr';

import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './config';
import configureStore from './store';

import 'jquery.easing/jquery.easing.min';
import $ from 'jquery';
window.jQuery = window.$ = $;
require('bootstrap');

// Amplify.Logger.LOG_LEVEL = 'DEBUG';

Amplify.configure({
	Auth: {
		mandatorySignIn: false,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID,
		authenticationFlowType: config.cognito.AUTH_FLOW_TYPE
	},
	API: {
		endpoints: [
			{
				name: 'gains-api',
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION
			}
		]
	}
});

ReactDOM.render(
	<Provider store={configureStore()}>
		<ConnectedFlagsProvider>
			<div>
				<ReduxToastr
					preventDuplicates
					transitionIn="fadeIn"
					transitionOut="fadeOut"
				/>
				<Router>
					<App />
				</Router>
			</div>
		</ConnectedFlagsProvider>
	</Provider>,
	document.getElementById('root')
);
serviceWorker.unregister();
