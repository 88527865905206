import React from 'react';
import { Button } from 'reactstrap';

export default ({
	isLoading,
	text,
	icon = '',
	loadingText,
	className = '',
	disabled = false,
	...props
}) => (
	<Button
		className={`LoaderButton ${className}`}
		disabled={disabled || isLoading}
		{...props}
	>
		{icon}
		{isLoading && <i className="fa fa-refresh fa-spin mr-2" />}
		{!isLoading ? text : loadingText}
	</Button>
);
