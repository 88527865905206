import { FETCH_LOCATIONS } from '../actions';
import { RESET_LOCATIONS } from '../actions';

const INITIAL_STATE = {
	regions: [],
	countries: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_LOCATIONS:
			const nonUniqueRegions = action.payload.map(({ region, region_id }) => ({
				name: region,
				id: region_id
			}));
			const ids = nonUniqueRegions.map((r) => r.id);
			const uniqueRegions = nonUniqueRegions.filter(
				({ id }, index) => !ids.includes(id, index + 1)
			);

			return {
				...state,
				regions: uniqueRegions,
				countries: action.payload.map(
					({ country, country_id, code, region_id }) => ({
						name: country,
						id: country_id,
						country_code: code,
						region_id: region_id
					})
				)
			};
		case RESET_LOCATIONS:
			return INITIAL_STATE;
		default:
			return state;
	}
};
