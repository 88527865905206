import React, { Component } from 'react';
import {
	Alert,
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	FormText,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from 'reactstrap';

import { Auth } from 'aws-amplify';
import BodyClassName from 'react-body-classname';
import DocumentTitle from 'react-document-title';
import ReactSVG from 'react-svg';

// import {
// 	getAuthenticatedUserGroups
// 	// getAuthenticatedUserAttributes
// } from 'actions';
import { cognitoError, LoaderButton, Terms } from 'components';

import './SignUp.scss';

import earthiLogo from 'images/earthi.svg';
import gainsLogo from 'images/gains-logo-dark.svg';

export default class SignUp extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			isLoading: false,
			givenName: '',
			familyName: '',
			organisation: '',
			password: '',
			confirmPassword: '',
			acceptTerms: false,
			confirmationCode: '',
			newUser: null,
			errorMsg: '',
			termsModal: false,
			groupsModal: false
		};

		this.toggleTermsModal = this.toggleTermsModal.bind(this);
	}

	// componentDidMount() {
	// 	ReactGA.pageview('/signUp', null, 'Sign Up');
	// }

	toggleTermsModal() {
		this.setState({
			termsModal: !this.state.termsModal
		});
	}

	validateForm() {
		const {
			email,
			givenName,
			familyName,
			organisation,
			password,
			confirmPassword,
			acceptTerms
		} = this.state;

		return (
			givenName.length > 0 &&
			familyName.length > 0 &&
			email.length > 0 &&
			organisation.length > 0 &&
			password.length > 0 &&
			password === confirmPassword &&
			acceptTerms
		);
	}

	validateConfirmationForm() {
		const { confirmationCode } = this.state;
		return confirmationCode.length > 0;
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
			errorMsg: ''
		});
	};

	handleCheckboxChange = (event) => {
		this.setState({
			[event.target.id]: event.target.checked,
			errorMsg: ''
		});
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		const {
			email,
			password,
			givenName: given_name,
			familyName: family_name,
			organisation,
			acceptTerms
		} = this.state;

		this.setState({
			isLoading: true
		});

		try {
			const newUser = await Auth.signUp({
				username: email,
				password,
				attributes: {
					given_name,
					family_name,
					'custom:organisation': organisation,
					'custom:acceptTerms': acceptTerms.toString(),
					'custom:signUpSource': 'https://navigate.gains.earthi.world'
				}
			});
			this.setState({
				newUser,
				isLoading: false
			});
		} catch (err) {
			this.setState({
				isLoading: false,
				errorMsg: cognitoError(err)
			});
		}
	};

	handleConfirmationSubmit = async (event) => {
		event.preventDefault();

		this.setState({
			isLoading: true
		});
		try {
			await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
			window.setTimeout(async () => {
				// await Auth.signIn(this.state.email, this.state.password);
				// const { username } = await getAuthenticatedUserAttributes();
				// const { authenticatedUserGroups: authGroups } =
				// 	await getAuthenticatedUserGroups();
				// this.props.userHasAuthenticated(true, authGroups);
				// this.props.history.push('/steel/home');
				this.props.history.push('/signUpConfirmation');
				//render message on SignUp
			}, 1000);
		} catch (err) {
			this.setState({
				isLoading: false,
				errorMsg: cognitoError(err)
			});
		}
	};

	renderConfirmationForm() {
		const { confirmationCode, errorMsg, isLoading } = this.state;

		return (
			<Form onSubmit={this.handleConfirmationSubmit}>
				<FormGroup>
					<Label for="confirmationCode">Verification code</Label>
					<Input
						id="confirmationCode"
						placeholder="Enter verification code"
						autoFocus
						value={confirmationCode}
						onChange={this.handleChange}
					/>
					<FormText color="muted">
						Please check your email for the verification code.
					</FormText>
				</FormGroup>
				{errorMsg && (
					<Alert color="warning" className="py-2 px-3">
						{errorMsg}
					</Alert>
				)}
				<LoaderButton
					block
					color="primary"
					disabled={!this.validateConfirmationForm()}
					type="submit"
					isLoading={isLoading}
					text="Verify"
					loadingText="Verifying…"
				/>
			</Form>
		);
	}

	buildTermsModal() {
		const { termsModal } = this.state;

		return (
			<Modal isOpen={termsModal} toggle={this.toggleTermsModal} fade size="lg">
				<ModalHeader toggle={this.toggleTermsModal}>
					Terms and Conditions for the Use of the GAINS Pilot Service
					<br />
					<span className="smaller">Last updated 16th December 2022</span>
				</ModalHeader>
				<ModalBody className="small signup-modal">
					<Terms />
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={this.toggleTermsModal}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	renderForm() {
		const {
			email,
			password,
			confirmPassword,
			givenName,
			familyName,
			organisation,
			acceptTerms,
			isLoading,
			errorMsg
		} = this.state;

		return (
			<Form onSubmit={this.handleSubmit}>
				<p>
					To access the GAINS application please fill in your details. All
					fields are required.
					<br />
					<span className="small font-italic text-muted">
						Please note that it may take up to 2 working days to approve your
						request.
					</span>
				</p>
				<FormGroup>
					<Label for="email">Email</Label>
					<Input
						id="email"
						placeholder="Enter email"
						value={email}
						onChange={this.handleChange}
					/>
				</FormGroup>

				<Row>
					<Col>
						<FormGroup>
							<Label for="givenName">First name</Label>
							<Input
								id="givenName"
								placeholder="Enter first name"
								value={givenName}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label for="familyName">Last name</Label>
							<Input
								id="familyName"
								placeholder="Enter last name"
								value={familyName}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<FormGroup>
					<Label for="organisation">Organisation</Label>
					<Input
						id="organisation"
						placeholder="Enter organisation"
						value={organisation}
						onChange={this.handleChange}
					/>
				</FormGroup>
				<Row>
					<Col>
						<FormGroup>
							<Label for="password">Password</Label>
							<Input
								id="password"
								type="password"
								placeholder="Enter password"
								value={password}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label for="confirmPassword">Confirm password</Label>
							<Input
								id="confirmPassword"
								type="password"
								placeholder="Enter password confirmation"
								value={confirmPassword}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<p className="help-block small text-muted font-italic">
					A password must have at least 8 characters and include an uppercase
					letter, a lowercase letter, a special character and a number
				</p>

				<FormGroup check className="mb-3">
					<Label for="acceptTerms" className="sr-only">
						Accept terms
					</Label>
					<Input
						id="acceptTerms"
						type="checkbox"
						value={acceptTerms}
						onChange={this.handleCheckboxChange}
					/>
					I have read and agree to the{' '}
					<Button
						color="link"
						id="termsButton"
						className="p-0 align-baseline"
						onClick={this.toggleTermsModal}
					>
						Terms and Conditions
					</Button>
				</FormGroup>

				{this.buildTermsModal()}

				{errorMsg && (
					<Alert color="warning" className="py-2 px-3">
						{errorMsg}
					</Alert>
				)}
				<LoaderButton
					block
					color="primary"
					disabled={!this.validateForm()}
					type="submit"
					isLoading={isLoading}
					text="Sign up"
					loadingText="Signing up…"
				/>
			</Form>
		);
	}

	render() {
		const { newUser } = this.state;

		return (
			<DocumentTitle title="GAINS | Sign up">
				<BodyClassName className="bg-dark">
					<Container fluid>
						<Card className="card-register mx-auto my-5">
							<CardHeader className="card-logo-header">
								<ReactSVG
									src={earthiLogo}
									className="card-logo"
									svgClassName="earthi-card-logo"
								/>
								<ReactSVG
									src={gainsLogo}
									className="card-logo"
									svgClassName="gains-card-logo"
								/>
								<h1 className="sr-only">GAINS</h1>
							</CardHeader>
							<CardBody>
								{newUser === null
									? this.renderForm()
									: this.renderConfirmationForm()}
								<div className="text-center">
									<a className="d-block small mt-3" href="/">
										Log in
									</a>
								</div>
							</CardBody>
						</Card>
					</Container>
				</BodyClassName>
			</DocumentTitle>
		);
	}
}
