import { API } from 'aws-amplify';

import {
	FETCH_STOCKPILES_DATA,
	FETCH_STOCKPILES_DATA_ERR,
	FETCH_STOCKPILES_DATA_BY_SITE,
	FETCH_STOCKPILES_DATA_BY_SITE_ERR
} from '.';
import config from '../config';
import { replacer } from '../utils';

export const fetchStockpilesData =
	({
		commodity,
		grouping,
		ids,
		dateFrom: startDate = '',
		dateTo: endDate = ''
	}) =>
	async (dispatch) => {
		const request = API.get(
			'gains-api',
			replacer(config.apiEndpoints.stockpilesData, {
				commodity,
				grouping,
				ids,
				startDate,
				endDate
			}),
			{
				headers: {}
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_STOCKPILES_DATA,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_STOCKPILES_DATA_ERR,
					payload: err
				})
		);
	};

export const fetchStockpilesDataInitial = async ({
	commodity,
	grouping,
	ids,
	startDate,
	endDate
}) => {
	return await API.get(
		'gains-api',
		replacer(config.apiEndpoints.stockpilesData, {
			commodity,
			grouping,
			ids,
			startDate,
			endDate
		}),
		{
			headers: {}
		}
	);
};

export const resetStockpilesData = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_STOCKPILES_DATA, payload: [] });
	};
};

export const fetchStockpilesDataBySite =
	({ commodity, ids, dateFrom: startDate = '', dateTo: endDate = '' }) =>
	async (dispatch) => {
		const request = API.get(
			'gains-api',
			replacer(config.apiEndpoints.stockpilesData, {
				commodity,
				ids,
				startDate,
				endDate
			}),
			{
				headers: {}
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_STOCKPILES_DATA_BY_SITE,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_STOCKPILES_DATA_BY_SITE_ERR,
					payload: err
				})
		);
	};

export const resetStockpilesDataBySite = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_STOCKPILES_DATA_BY_SITE, payload: {} });
	};
};
