import {
	FETCH_STEEL_SMELTER_CAPACITY_INIT,
	FETCH_STEEL_SMELTER_CAPACITY,
	RESET_STEEL_SMELTER_CAPACITY
} from '../../actions';

const INITIAL_STATE = {
	timestamp: '',
	data: ''
};

//response is valid if request timestamp is fresher
function isResponseValid(t1, t2) {
	return t2 >= t1;
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_STEEL_SMELTER_CAPACITY_INIT:
			return {
				...state,
				...action.payload
			};
		case FETCH_STEEL_SMELTER_CAPACITY: {
			const { data, timestamp } = action.payload;
			if (isResponseValid(state.timestamp, timestamp)) {
				return {
					...state,
					data
				};
			}
			return state;
		}
		case RESET_STEEL_SMELTER_CAPACITY: {
			return INITIAL_STATE;
		}
		default:
			return state;
	}
};
