import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import config from '../../config';

const ApiModal = ({ open, toggle }) => (
	<Modal
		size="xl"
		isOpen={open}
		labelledBy="apiUseModalLabel"
		aria-hidden="true"
	>
		<ModalHeader toggle={toggle}>
			<span id="apiUseModalLabel">
				<h4 className="mb-0"> 3rd-party API Access</h4>
			</span>
		</ModalHeader>
		<ModalBody>
			<h5>Programmatic access</h5>
			<p>
				A user developing their own solution that needs access to the GAINS API,
				may wish to perform a Cognito login within that application. In addition
				to a valid username and password, the configuration required for this
				is:
			</p>
			<dl className="mb-5">
				<dt>API URL</dt>
				<dd>{config.apiGateway.URL}</dd>
				<dt>Region</dt>
				<dd>{config.cognito.REGION}</dd>
				<dt>User Pool ID</dt>
				<dd>{config.cognito.USER_POOL_ID}</dd>
				<dt>Identity Pool ID</dt>
				<dd>{config.cognito.IDENTITY_POOL_ID}</dd>
				<dt>Client ID</dt>
				<dd>{config.cognito.APP_CLIENT_ID}</dd>
			</dl>
			<h5>API Endpoints</h5>
			<p>
				After you have authenticated to the GAINS platform, you can send
				requests to the API using any programming language or tool that can send
				HTTP requests. All requests are made to endpoints beginning:{' '}
				{config.apiGateway.URL} and must be secure, i.e. https, not http. To
				help you get started, an example API request is given below.
			</p>
			<p>
				Get daily/monthly/weekly/annual production figures (p) per smelter
				regions or countries or groups by their ids, optionally constrained to
				certain dates and plant type:
				<br />
				<strong>
					{config.apiGateway.URL}
					/smelter-production/countries/78,2,268?commodity=steel&amp;collapse=daily&amp;plant=bfbof&amp;startDate=20230219&amp;endDate=20240219
				</strong>
				<br />
				<br />
				For more details please refer to{' '}
				<a href="https://earth-i.atlassian.net/wiki/external/2785902593/MmJmZmRlZWVlMGVjNDFiMDk4MmQxOWU0NmUyMmMwYWY">
					GAINS API User Guide
				</a>
				.
			</p>
		</ModalBody>
		<ModalFooter>
			<Button color="secondary" aria-label="Close" onClick={toggle}>
				Close
			</Button>
		</ModalFooter>
	</Modal>
);

export default ApiModal;
