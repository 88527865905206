import React, { Component } from 'react';
import BodyClassName from 'react-body-classname';
import DocumentTitle from 'react-document-title';
import { Card, CardHeader, CardBody } from 'reactstrap';
// import ReactGA from 'react-ga';

export default class NotFound extends Component {
	componentDidMount() {
		// ReactGA.pageview('/notFound', null, 'Page Not Found');
	}

	render() {
		return (
			<DocumentTitle title="GAINS | Page Not Found">
				<BodyClassName className="bg-dark">
					<div className="container">
						<Card className="card-login mx-auto mt-5">
							<CardHeader>
								<i className="fa fa-flash fa-lg mr-3" />
								Page not found
							</CardHeader>
							<CardBody>
								<p>
									I'm terribly sorry, but that feature is not currently
									available. If this is an unexpected fault please contact your
									systems administrator for assistance.
								</p>
								<p className="text-center mb-0">
									<a href="/steel/home">Return to dashboard</a>
								</p>
							</CardBody>
						</Card>
					</div>
				</BodyClassName>
			</DocumentTitle>
		);
	}
}
