import { API } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';

import config from 'config';
import { replacer } from '../utils';

// export const downloadKml = async ({ id }) => {
// 	return await API.get(
// 		'gains-api',
// 		replacer(config.apiEndpoints.kmlDownload, {
// 			id
// 		}),
// 		{
// 			headers: {},
// 			responseType: 'text'
// 		}
// 	);
// };

export const downloadKml = async ({ id }) => {
	try {
		const url = await API.get(
			'gains-api',
			replacer(config.apiEndpoints.kmlDownload, {
				id
			}),
			{
				headers: {},
				responseType: 'text'
			}
		);

		if (!url) {
			toastr.error('Missing url!');
			return;
		}
		// window.open(url, '_blank', 'download=test.kml');

		const a = document.createElement('a');
		//quotes removed since it doesn't work with npm package manager
		a.href = url.replace(/^"(.+(?="$))"$/, '$1');
		// a.setAttribute('download', `${id}.kml`);
		a.click();
		a.remove();
	} catch (err) {
		const innerMsg = err?.response?.data?.message;
		const message = `${err.message}. ${innerMsg ? innerMsg + '.' : ''}`;
		toastr.error(message);
	}
};
