import { FETCH_LAST_UPLOAD_DATE } from '../actions';

export default (state = '', action) => {
	switch (action.type) {
		case FETCH_LAST_UPLOAD_DATE:
			return action.payload;

		default:
			return state;
	}
};
