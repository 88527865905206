// import {
// 	getGroupsPerType,
// 	getGroupsPremiumHigher,
// 	getGroupsSalesEnterprise
// } from '../utils';
const menuConfig = {
	steel: [
		{
			path: '/steel/home',
			title: 'Steel Dashboard',
			icon: 'fa-dashboard',
			groups: []
		},
		// {
		// 	path: '/steel/smelterInfo',
		// 	title: 'Smelter Process',
		// 	icon: 'fa-flask',
		// 	groups: getGroupsPerType('steel')
		// },
		{
			path: '/steel/smelters',
			title: 'Smelter Operations',
			icon: 'fa-fire'
			// groups: getGroupsPerType('steel')
		},
		{
			path: '/steel/smeltersMap',
			title: 'Smelter Map',
			icon: 'fa-map-o'
			// groups: getGroupsSalesEnterprise('steel')
		},
		{
			path: '/steel/smeltersCapacity',
			title: 'Smelter Capacity',
			icon: 'fa-line-chart'
		},
		{
			path: '/steel/smeltersProduction',
			subpaths: [
				{ name: 'Sites', hasChildren: false },
				{ name: 'Subsites', hasChildren: true }
			],
			title: 'Smelter Production',
			icon: 'fa-bar-chart'
		},
		{
			path: '/steel/smeltersCO2Emissions',
			title: 'CO2 Emissions',
			icon: 'fa-industry',
			userRoles: ['superUser', 'admin']
			// groups: getGroupsPerType('steel')
		}
	],
	ironOre: [
		{
			path: '/ironOre/indices',
			title: 'Iron Ore Data',
			icon: 'fa-line-chart'
		}
	],
	scrapSteel: [
		{
			path: '/scrapSteel/indices',
			title: 'Scrap Steel Data',
			icon: 'fa-line-chart'
		}
	]
};

// const menuConfig = [
// 	{
// 		type: 'smelters',
// 		path: '/steel/smelters',
// 		title: 'Steel Indices',
// 		icon: 'fa-line-chart'
// 		// groups: getGroupsPerType('steel')
// 	},

// 	{
// 		type: 'smelters',
// 		path: '/steel/smeltersMap',
// 		title: 'Smelter Map',
// 		icon: 'fa-map-o'
// 		// groups: getGroupsSalesEnterprise('steel')
// 	},

// 	{
// 		type: 'stockpiles',
// 		path: '/ironOre/indices',
// 		title: 'Iron Ore Data',
// 		icon: 'fa-line-chart'
// 		// groups: getGroupsPerType('steel')
// 	}
// ];

export default menuConfig;
