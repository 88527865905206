import React, { Component } from 'react';
import Map, { NavigationControl, Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import SiteMarker from './SiteMarker';
import SitePopup from './SitePopup';
// import MapStyleMenu from './MapStyleMenu';
import { fetchSiteGeoJson } from 'actions';
import './Map.scss';

// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
mapboxgl.workerClass = MapboxWorker;

const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

class SiteMap extends Component {
	constructor() {
		super();
		this.state = {
			viewport: {
				// width: '100vw',
				// height: '100vh',
				latitude: 30,
				longitude: 15,
				zoom: 1,
				padding: { top: 20, bottom: 25, left: 50, right: 20 }
			},
			mapStyle: 'mapbox://styles/mapbox/satellite-v9',
			isStyleLoading: false,
			selectedIds: [],
			visibleSites: [],
			geoJSONData: [],
			activePopupId: '',
			updatedPopupLat: '',
			updatedPopupLng: '',
			geoJSONRequested: false
		};
		this.map = null;
		this.observer = null;
	}

	componentDidMount() {
		const { sites } = this.props;
		if (sites.length) {
			this.handleVisibleSites();
		}
	}

	async componentDidUpdate(
		{ sites: prevSites },
		{ visibleSites: prevVisibleSites, viewport: { zoom: prevZoom } }
	) {
		const { sites } = this.props;
		const {
			visibleSites,
			geoJSONData,
			viewport: { zoom },
			geoJSONRequested
		} = this.state;

		if (geoJSONRequested) return;

		if (sites.length && sites.length !== prevSites.length) {
			if (prevSites.length === 0) {
				this.handleVisibleSites();
			}
			const coords = sites.map(({ lon, lat }) => [lon, lat]);
			const bounds = coords.reduce(
				(bounds, coord) => bounds.extend(coord),
				new mapboxgl.LngLatBounds(coords[0], coords[0])
			);

			if (this.map) {
				if (!this.observer) {
					this.observer = new ResizeObserver(() => {
						this.map.resize();
					});
					const mapContainer = this.map.getContainer();
					this.observer.observe(mapContainer);
				}
				this.map.fitBounds(bounds);
			}
			return;
		}

		if (
			visibleSites.length &&
			zoom >= 12 &&
			(visibleSites.length !== prevVisibleSites.length ||
				//  ||
				// visibleSites.some((s) => !prevVisibleSites.includes(s))
				zoom > prevZoom)
		) {
			const newSites = visibleSites.filter(
				(s) => !geoJSONData.map(({ id }) => id).includes(s)
			);

			let data = [];
			if (newSites.length) {
				this.setState({ geoJSONRequested: true });
				data = await fetchSiteGeoJson({ id: newSites });
				this.setState({ geoJSONRequested: false });
				data = newSites.length === 1 ? [data] : data;
			}

			this.setState({
				geoJSONData: [
					...geoJSONData,
					...data.map(({ id, aoi }) => ({ id, aoi }))
				]
			});
		}
	}

	componentWillUnmount() {
		if (this.observer) {
			this.observer.disconnect();
		}
	}

	closePopup = () => {
		this.setState({
			selectedIds: [],
			activePopupId: ''
		});
	};

	openPopup = (ids) => {
		this.setState({ selectedIds: ids });
	};

	handleVisibleSites() {
		if (!this.map) {
			this.props.onVisibleSitesUpdated(this.props.sites.map(({ id }) => id));
			return;
		}
		const mapContainer = this.map.getContainer();

		const markers = mapContainer.getElementsByClassName('marker');
		const visibles = [];
		if (markers.length) {
			const boundRect = mapContainer.getBoundingClientRect();

			Array.from(markers).forEach((m) => {
				const markerRect = m.getBoundingClientRect();
				const isIntersect = !(
					markerRect.left > boundRect.right ||
					markerRect.right < boundRect.left ||
					markerRect.top > boundRect.bottom ||
					markerRect.bottom < boundRect.top
				);
				if (isIntersect) {
					visibles.push(parseInt(m.id));
				}
			});
		}

		this.setState({ visibleSites: visibles });
		this.props.onVisibleSitesUpdated(visibles);
	}

	handleViewportChange = (viewState) => {
		const { viewport } = this.state;
		this.setState({ viewport: { ...viewport, ...viewState } });
		this.handleVisibleSites();
	};

	// handleZoom = (e) => {
	// 	console.log('e zoom', e);
	// 	const zoom = e.target.getZoom();
	// 	console.log('zoom1', zoom);
	// 	console.log('zoom2', this.state.viewport.zoom);
	// 	this.handleVisibleSites();
	// };

	renderMarkers = () => {
		const { sites } = this.props;
		const { selectedIds, activePopupId } = this.state;

		const displayMarker = this.map?.getZoom() < 13;
		return sites.map((site) => (
			<SiteMarker
				key={`marker-${site.id}`}
				selectedId={activePopupId ? null : selectedIds[0]}
				displayMarker={displayMarker}
				site={site}
				openPopup={() => this.openPopup([site.id])}
			/>
		));
	};

	renderPolygons = () => {
		const { visibleSites, geoJSONData, activePopupId } = this.state;

		const filteredGeoJSON =
			this.map.getZoom() >= 12
				? geoJSONData.filter(({ id }) => visibleSites.includes(id))
				: [];

		return filteredGeoJSON.map(({ id, aoi }) => (
			<Source
				key={id}
				type="geojson"
				data={{
					type: 'Feature',
					properties: { id },
					geometry: aoi
				}}
			>
				<Layer
					{...{
						id: `site-${id}`,
						type: 'fill',
						paint: {
							'fill-color': 'transparent',
							'fill-opacity': 1.0
						}
					}}
				/>
				{activePopupId !== id && (
					<Layer
						{...{
							id: `outline-site-${id}`,
							type: 'line',
							paint: {
								'line-color': 'red',
								'line-width': 2
							}
						}}
					/>
				)}
				{activePopupId === id && (
					<Layer
						{...{
							id: `popup-site-${id}`,
							type: 'line',
							paint: {
								'line-color': 'blue',
								'line-width': 4,
								'line-dasharray': [0, 2]
							},
							layout: {
								'line-cap': 'round'
							}
						}}
					/>
				)}
			</Source>
		));
	};

	handleOnClickPolygon = ({ features }) => {
		if (features.length) {
			const ids = features.map((f) => f.properties.id);
			this.openPopup(ids);
		}
	};

	handleOnMouseEnterPolygon = ({ features, target: map }) => {
		if (features.length && map.getZoom() >= 12) {
			map.getCanvas().style.cursor = 'pointer';
		}
	};

	handleOnMouseLeavePolygon = ({ features, target: map }) => {
		if (features.length) {
			map.getCanvas().style.cursor = '';
		}
	};

	handleActivePopup = (id) => {
		if (this.map.getZoom() > 12) this.setState({ activePopupId: id });
	};

	handleMapStyle = (style) => {
		this.setState({ mapStyle: style });
	};

	handleStyleLoaded = (e) => {
		this.setState({ isStyleLoading: false });
	};

	render() {
		const {
			selectedIds,
			visibleSites,
			mapStyle,
			geoJSONData,
			viewport: { zoom }
		} = this.state;

		return (
			<Map
				mapStyle={mapStyle}
				{...this.state.viewport}
				minZoom={1}
				maxZoom={15}
				onLoad={({ target }) => (this.map = target)}
				// onStyleData={(e) => this.handleStyleLoaded(e)}
				onMove={({ viewState }) => this.handleViewportChange(viewState)}
				mapboxAccessToken={mapboxToken}
				// onZoom={({ viewState }) => this.handleViewportChange(viewState)}
				interactiveLayerIds={visibleSites.map((id) => `site-${id}`)}
				onClick={this.handleOnClickPolygon}
				onMouseEnter={this.handleOnMouseEnterPolygon}
				onMouseLeave={this.handleOnMouseLeavePolygon}
			>
				{this.renderMarkers()}
				{visibleSites.length && this.renderPolygons()}
				{selectedIds.length && (
					<SitePopup
						updatedLngLat={
							geoJSONData.length && zoom >= 12
								? this.map.getBounds().getNorthEast()
								: {}
						}
						sites={this.props.sites?.filter(({ id }) =>
							selectedIds.includes(id)
						)}
						closePopup={this.closePopup}
						setActivePopup={(id) => this.handleActivePopup(id)}
					/>
				)}
				<NavigationControl position={'top-left'} showCompass={false} />
				{/* <MapStyleMenu switchMapStyle={(style) => this.handleMapStyle(style)} /> */}
			</Map>
		);
	}
}

export default SiteMap;
