import React from 'react';
import Select from 'react-select';

import { multiSelectStyle } from './multiSelectStyle';

const MultiSelect = ({
	options,
	values,
	limit,
	disabled,
	onMultipleSelection
}) => {
	return (
		<Select
			isMulti
			options={options.map(({ id, name }) => ({
				value: id,
				label: name
			}))}
			isOptionDisabled={() => values?.length === limit}
			value={
				options
					.filter((o) => values?.map(({ id }) => id).includes(o.id))
					.map(({ id, name }) => ({ value: id, label: name })) || []
			}
			onChange={(val) =>
				onMultipleSelection(
					val?.map(({ value: id, label: name }) => ({
						id,
						name
					})) || []
				)
			}
			isDisabled={disabled}
			className="react-select-container d-inline-block mb-2 ml-3"
			classNamePrefix="react-select"
			placeholder="Please select..."
			styles={multiSelectStyle}
		/>
	);
};
export default MultiSelect;
