import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const BASE_PATH = {
	cu: {
		path: '/home',
		title: 'Copper Dashboard'
	},
	ni: {
		path: '/ni/home',
		title: 'Nickel Dashboard'
	},
	steel: {
		path: '/steel/home',
		title: 'Steel Dashboard'
	},
	fe: {
		path: '',
		title: ''
	}
};

class Crumbs extends Component {
	static propTypes = {
		location: PropTypes.object.isRequired
	};

	render() {
		const { type = 'cu' } = this.props;

		const isBasePath = this.props.location.pathname === BASE_PATH[type].path;
		return (
			<ol className="breadcrumb py-2">
				{BASE_PATH[type].path && (
					<li className={'breadcrumb-item' + (isBasePath ? 'active' : '')}>
						{!isBasePath ? (
							<NavLink exact to={BASE_PATH[type].path}>
								{BASE_PATH[type].title}
							</NavLink>
						) : (
							BASE_PATH[type].title
						)}
					</li>
				)}
				{this.props.path &&
					this.props.path.map((crumbItem, i) => {
						return (
							<li
								key={i}
								className={
									'breadcrumb-item' +
									(this.props.location.pathname === crumbItem.path
										? ' active'
										: '')
								}
							>
								{!crumbItem.path && crumbItem.title}
							</li>
						);
					})}
			</ol>
		);
	}
}

export default withRouter(Crumbs);
