import React from 'react';
import { Route, Switch } from 'react-router-dom';

import {
	ForgotPassword,
	Login,
	SteelHome,
	SteelSmelterInfo,
	SteelSmelters,
	SteelSmeltersCapacity,
	SteelSmeltersProduction,
	SteelSmeltersProductionSubsites,
	SteelSmeltersCO2Emissions,
	SteelSmeltersMap,
	NotFound,
	SignUp,
	SignUpConfirmation,
	IronOre,
	ScrapSteel
} from 'screens';
import { AppliedRoute } from 'components';
// import { getGroupsPerType } from './utils';

export default ({ childProps }) => (
	<Switch>
		<AppliedRoute
			path="/"
			exact
			component={Login}
			props={childProps}
			noTemplate={true}
			insecure={true}
		/>

		<AppliedRoute
			path="/signUp"
			exact
			component={SignUp}
			props={childProps}
			noTemplate={true}
			insecure={true}
		/>
		<AppliedRoute
			path="/signUpConfirmation"
			exact
			component={SignUpConfirmation}
			props={childProps}
			noTemplate={true}
			insecure={true}
		/>
		<AppliedRoute
			path="/forgotPassword"
			exact
			component={ForgotPassword}
			props={childProps}
			noTemplate={true}
			insecure={true}
		/>

		{/* Steel routes */}
		<AppliedRoute
			path="/steel/home"
			exact
			component={SteelHome}
			props={childProps}
			type="steel"
		/>
		<AppliedRoute
			path="/steel/smelterInfo"
			exact
			component={SteelSmelterInfo}
			props={childProps}
			// groupProtect={getGroupsPerType('steel')}
			type="steel"
		/>
		<AppliedRoute
			path="/steel/smelters"
			exact
			component={SteelSmelters}
			props={childProps}
			type="steel"
		/>
		<AppliedRoute
			path="/steel/smeltersCapacity"
			exact
			component={SteelSmeltersCapacity}
			props={childProps}
			type="steel"
		/>
		<AppliedRoute
			path="/steel/smeltersProduction/sites"
			exact
			component={SteelSmeltersProduction}
			props={childProps}
			type="steel"
		/>
		<AppliedRoute
			path="/steel/smeltersProduction/subsites/:sType"
			exact
			component={SteelSmeltersProductionSubsites}
			props={childProps}
			type="steel"
		/>
		<AppliedRoute
			path="/steel/smeltersCO2Emissions"
			exact
			component={SteelSmeltersCO2Emissions}
			props={childProps}
			type="steel"
		/>
		<AppliedRoute
			path="/steel/smeltersMap/:regionKey?"
			exact
			component={SteelSmeltersMap}
			props={childProps}
			// groupProtect={getGroupsSalesEnterprise('steel')}
			type="steel"
		/>
		<AppliedRoute
			path="/ironOre/indices"
			exact
			component={IronOre}
			props={childProps}
			type="ironOre"
			alias="fe"
		/>
		<AppliedRoute
			path="/scrapSteel/indices"
			exact
			component={ScrapSteel}
			props={childProps}
			type="scrapSteel"
			alias="scrap_steel"
		/>
		<Route component={NotFound} />
	</Switch>
);
