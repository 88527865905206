export const FETCH_LAST_UPLOAD_DATE = 'fetch_last_upload_date';
export const FETCH_LAST_UPLOAD_DATE_ERR = 'fetch_last_upload_date_err';
export const FETCH_LOCATIONS = 'fetch_locations';
export const RESET_LOCATIONS = 'reset_locations';
export const FETCH_LOCATIONS_ERR = 'fetch_locations_err';
export const FETCH_GROUPS = 'fetch_groups';
export const RESET_GROUPS = 'reset_groups';
export const FETCH_GROUPS_ERR = 'fetch_groups_err';
export const FETCH_SITES = 'fetch_sites';
export const FETCH_SITES_ERR = 'fetch_sites_err';

export const FETCH_SITE_TYPES = 'fetch_site_types';
export const FETCH_SITE_TYPES_ERR = 'fetch_site_types_err';

export const FETCH_MYSMELTERS = 'fetch_mysmelters';
export const FETCH_MYSMELTERS_ERR = 'fetch_mysmelters_err';

export const SHRINK_SMELTERS_ALL_TIME_MAP_DATA =
	'shrink_smelters_all_time_map_data';

//steel
export const FETCH_STEEL_LOCATIONS = 'fetch_steel_locations';
export const RESET_STEEL_LOCATIONS = 'reset_steel_locations';
export const FETCH_STEEL_LOCATIONS_ERR = 'fetch_steel_locations_err';

export const FETCH_STEEL_GROUPS = 'fetch_steel_groups';
export const FETCH_STEEL_GROUPS_ERR = 'fetch_steel_groups_err';

export const FETCH_STEEL_LAST_UPLOAD_DATE = 'fetch_steel_last_upload_date';
export const FETCH_STEEL_LAST_UPLOAD_DATE_ERR =
	'fetch_steel_last_upload_date_err';

export const FETCH_STEEL_PSEUDOGROUPS = 'fetch_steel_pseudogroups';
export const FETCH_STEEL_PSEUDOGROUPS_ERR = 'fetch_steel_pseudogroups_err';

export const FETCH_STEEL_SMELTER_ALL_MAP_DATA =
	'fetch_steel_smelter_all_map_data';
export const FETCH_STEEL_SMELTER_ALL_MAP_DATA_ERR =
	'fetch_steel_smelter_all_map_data_err';

export const FETCH_STEEL_SMELTER_ALL_TIME_BY_SMELTER_DATA =
	'fetch_steel_smelter_all_time_by_smelter_data';
export const FETCH_STEEL_SMELTER_ALL_TIME_BY_SMELTER_DATA_ERR =
	'fetch_steel_smelter_all_time_by_smelter_data_err';

export const FETCH_STEEL_SMELTER_DATA_INIT = 'fetch_steel_smelter_data_init';
export const FETCH_STEEL_SMELTER_DATA = 'fetch_steel_smelter_data';
export const FETCH_STEEL_SMELTER_DATA_ERR = 'fetch_steel_smelter_data_err';
export const RESET_STEEL_SMELTER_DATA = 'reset_steel_smelter_data';

export const FETCH_STEEL_SMELTER_CAPACITY_INIT =
	'fetch_steel_smelter_capacity_init';
export const FETCH_STEEL_SMELTER_CAPACITY = 'fetch_steel_smelter_capacity';
export const FETCH_STEEL_SMELTER_CAPACITY_ERR =
	'fetch_steel_smelter_capacity_err';
export const RESET_STEEL_SMELTER_CAPACITY = 'reset_steel_smelter_capacity';

export const FETCH_STEEL_SMELTER_PRODUCTION_INIT =
	'fetch_steel_smelter_production_init';
export const FETCH_STEEL_SMELTER_PRODUCTION = 'fetch_steel_smelter_production';
export const FETCH_STEEL_SMELTER_PRODUCTION_ERR =
	'fetch_steel_smelter_production_err';
export const RESET_STEEL_SMELTER_PRODUCTION = 'reset_steel_smelter_production';

export const FETCH_STEEL_SMELTER_PRODUCTION_SUBSITES_INIT =
	'fetch_steel_smelter_production_subsites_init';
export const FETCH_STEEL_SMELTER_PRODUCTION_SUBSITES =
	'fetch_steel_smelter_production_subsites';
export const FETCH_STEEL_SMELTER_PRODUCTION_SUBSITES_ERR =
	'fetch_steel_smelter_production_subsites_err';
export const RESET_STEEL_SMELTER_PRODUCTION_SUBSITES =
	'reset_steel_smelter_production_subsites';

export const FETCH_STEEL_SMELTER_CO2_EMISSIONS_INIT =
	'fetch_steel_smelter_co2_emissions_init';
export const FETCH_STEEL_SMELTER_CO2_EMISSIONS =
	'fetch_steel_smelter_co2_emissions';
export const FETCH_STEEL_SMELTER_CO2_EMISSIONS_ERR =
	'fetch_steel_smelter_co2_emissions_err';
export const RESET_STEEL_SMELTER_CO2_EMISSIONS =
	'reset_steel_smelter_co2_emissions';

export const FETCH_STEEL_SMELTER_DATA_FREE = 'fetch_steel_smelter_data_free';
export const FETCH_STEEL_SMELTER_DATA_FREE_ERR =
	'fetch_steel_smelter_data_free_err';

export const FETCH_STEEL_SMELTER_LIST = 'fetch_steel_smelter_list';
export const FETCH_STEEL_SMELTER_LIST_ERR = 'fetch_steel_smelter_list_err';

export const FETCH_STEEL_SMELTERS_STATE = 'fetch_steel_smelters_state';
export const FETCH_STEEL_SMELTERS_STATE_ERR = 'fetch_steel_smelters_state_err';

export const FETCH_STEEL_SMELTER_RECENT = 'fetch_steel_smelter_recent';
export const FETCH_STEEL_SMELTER_RECENT_ERR = 'fetch_steel_smelter_recent_err';

export const FETCH_STEEL_SMELTER_RECENT_UPDATES =
	'fetch_steel_smelter_recent_updates';
export const FETCH_STEEL_SMELTER_RECENT_UPDATES_ERR =
	'fetch_steel_smelter_recent_updates_err';

export const FETCH_STEEL_SMELTER_REGIONS = 'fetch_steel_smelter_regions';
export const FETCH_STEEL_SMELTER_REGIONS_ERR =
	'fetch_steel_smelter_regions_err';

export const FETCH_STEEL_SMELTER_UPDATES = 'fetch_steel_smelter_updates';
export const FETCH_STEEL_SMELTER_UPDATES_ERR =
	'fetch_steel_smelter_updates_err';

export const RESET_STEEL_SMELTER_MAP_DATA = 'reset_steel_smelter_map_data';

export const SELECT_STEEL_SMELTER = 'select_steel_smelter';
export const UNSELECT_STEEL_SMELTER = 'unselect_steel_smelter';

//stockpiles
export const FETCH_STOCKPILES_DATA = 'fetch_stockpiles_data';
export const FETCH_STOCKPILES_DATA_ERR = 'fetch_stockpiles_data_err';
export const FETCH_STOCKPILES_DATA_BY_SITE = 'fetch_stockpiles_data_by_site';
export const FETCH_STOCKPILES_DATA_BY_SITE_ERR =
	'fetch_stockpiles_data_by_site_err';

//stockpiles
export const FETCH_SCRAP_STEEL_DATA = 'fetch_scrap_steel_data';
export const FETCH_SCRAP_STEEL_DATA_ERR = 'fetch_scrap_steel_data_err';
export const FETCH_SCRAP_STEEL_DATA_BY_SITE = 'fetch_scrap_steel_data_by_site';
export const FETCH_SCRAP_STEEL_DATA_BY_SITE_ERR =
	'fetch_scrap_steel_data_by_site_err';
