//cognito user groups
const allTypes = ['cu', 'ni', 'steel'];

const cognitoGroups = {
	free: {
		cu: {
			required: 'savant-free',
			excludes: [
				'savant-premium',
				'savant-premium-plus',
				'sales',
				'savant-enterprise'
			]
		},
		ni: {
			required: 'savant-ni-free',
			excludes: [
				'savant-ni-premium',
				'savant-ni-premium-plus',
				'sales-ni',
				'savant-ni-enterprise'
			]
		},
		steel: {
			required: 'savant-steel-free',
			excludes: [
				'savant-steel-premium',
				'savant-steel-premium-plus',
				'sales-steel',
				'savant-steel-enterprise'
			]
		}
	},

	premium: {
		cu: {
			required: 'savant-premium',
			excludes: ['savant-premium-plus', 'sales', 'savant-enterprise']
		},
		ni: {
			required: 'savant-ni-premium',
			excludes: ['savant-ni-premium-plus', 'sales-ni', 'savant-ni-enterprise']
		},
		steel: {
			required: 'savant-steel-premium',
			excludes: [
				'savant-steel-premium-plus',
				'sales-steel',
				'savant-steel-enterprise'
			]
		}
	},

	premiumPlus: {
		cu: {
			required: 'savant-premium-plus',
			excludes: ['sales', 'savant-enterprise']
		},
		ni: {
			required: 'savant-ni-premium-plus',
			excludes: ['sales-ni', 'savant-ni-enterprise']
		},
		steel: {
			required: 'savant-steel-premium-plus',
			excludes: ['sales-steel', 'savant-steel-enterprise']
		}
	},

	sales: {
		cu: {
			required: 'sales',
			excludes: ['savant-enterprise']
		},
		ni: {
			required: 'sales-ni',
			excludes: ['savant-ni-enterprise']
		},
		steel: {
			required: 'sales-steel',
			excludes: ['savant-steel-enterprise']
		}
	},

	enterprise: {
		cu: {
			required: 'savant-enterprise',
			excludes: []
		},
		ni: {
			required: 'savant-ni-enterprise',
			excludes: []
		},
		steel: {
			required: 'savant-steel-enterprise',
			excludes: []
		}
	}
};

const envConfig = {
	apiGateway: {
		URL: process.env.REACT_APP_URL,
		REGION: process.env.REACT_APP_REGION
	},
	cognito: {
		REGION: process.env.REACT_APP_REGION,
		USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
		APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
		IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
		AUTH_FLOW_TYPE: process.env.REACT_APP_AUTH_FLOW_TYPE
	},
	ion: {
		accessToken: process.env.REACT_APP_ION_ACCESS_TOKEN
	},
	google: {
		ANALYTICS_ID: 'G-MVMRT4KQ4S',
		GA_ENABLED: process.env.REACT_APP_GA_ENABLED,
		TEST_MODE: true
	},

	idleTimeout: 1000 * 60 * 30
};

const apiEndpoints = {
	kmlDownload: '/v1/steel/kml-download/${id}', // eslint-disable-line no-template-curly-in-string
	lastUploadDate: '/v1/last-upload-date?commodity=${commodity}', // eslint-disable-line no-template-curly-in-string
	groups: '/v1/groups?commodity=${commodity}&siteType=${siteType}', // eslint-disable-line no-template-curly-in-string
	locations: '/v1/locations?commodity=${commodity}&siteType=${siteType}', // eslint-disable-line no-template-curly-in-string
	listSites: '/v1/sites?commodity=${commodity}', // eslint-disable-line no-template-curly-in-string
	listSiteTypes: '/v1/site-types', // eslint-disable-line no-template-curly-in-string
	geoJsonData: '/v1/sites/${id}/info', // eslint-disable-line no-template-curly-in-string

	//ironOre
	stockpilesData:
		'/v1/${commodity}/total-pixels/${grouping}/${ids}?startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterData:
		'/v1/steel/index/${grouping}/${ids}?plant=${plant}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterCapacity:
		'/v1/steel/capacity/${grouping}/${ids}?plant=${plant}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterProduction:
		'/v1/steel/production/${grouping}/${ids}?collapse=${collapse}&plant=${plant}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterCO2Emissions:
		'/v1/steel/co2-emissions/${grouping}/${ids}?collapse=${collapse}&plant=${plant}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	worldSteelRegions: '/v1/steel/${subsiteType}/world-regions', // eslint-disable-line no-template-curly-in-string
	worldSteelData:
		'/v1/steel/world-data/${grouping}/${id}?collapse=${collapse}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	worldSteelSubsitesData:
		'/v1/steel/${subsiteType}/world-data/${grouping}/${id}?collapse=${collapse}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterProductionSubsites:
		'/v1/steel/${subsiteType}/production/${grouping}/${ids}?collapse=${collapse}&startDate=${startDate}&endDate=${endDate}', // eslint-disable-line no-template-curly-in-string
	// steelSmelterAllMapData: '/smelter-map-data-all?type=steel',
	steelSmelterAllTimeBySmelter: '/v1/steel/data/sites/${smelterIds}', // eslint-disable-line no-template-curly-in-string
	steelSmeltersStatus:
		'/v1/steel/states/${grouping}/${ids}?plantType=${plantType}&searchDate=${searchDate}', // eslint-disable-line no-template-curly-in-string
	steelSmelterRecent: '/v1/steel/recent-states/'
};

export default {
	apiEndpoints,
	allTypes,
	cognitoGroups,
	...envConfig
};
