import React, { Component } from 'react';
import {
	Alert,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Row
} from 'reactstrap';
import { Auth } from 'aws-amplify';
import BodyClassName from 'react-body-classname';
import DocumentTitle from 'react-document-title';
import ReactSVG from 'react-svg';
// import ReactGA from 'react-ga';

import { cognitoError, LoaderButton } from 'components';

import earthiLogo from 'images/earthi.svg';
import savantLogo from 'images/savant.svg';

export default class ForgotPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			username: '',
			confirmationCode: '',
			password: '',
			confirmPassword: '',
			confirmResponse: null,
			resetSuccess: false,
			errorMsg: ''
		};
	}

	componentDidMount() {
		// ReactGA.pageview('/forgotPassword', null, 'Forgot Password');
	}

	validateForm() {
		return this.state.username.length > 0;
	}

	validateConfirmationForm() {
		return (
			this.state.confirmationCode.length > 0 &&
			this.state.password.length > 0 &&
			this.state.password === this.state.confirmPassword
		);
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
			errorMsg: ''
		});
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		this.setState({
			isLoading: true
		});

		try {
			const confirmResponse = await Auth.forgotPassword(this.state.username);
			this.setState({
				confirmResponse,
				errorMsg: ''
			});
		} catch (err) {
			this.setState({
				errorMsg: cognitoError(err)
			});
		}

		this.setState({
			isLoading: false
		});
	};

	handleConfirmationSubmit = async (event) => {
		event.preventDefault();
		this.setState({
			isLoading: true
		});

		try {
			await Auth.forgotPasswordSubmit(
				this.state.username,
				this.state.confirmationCode,
				this.state.password
			);
			this.setState({
				resetSuccess: true,
				isLoading: false
			});
		} catch (err) {
			this.setState({
				isLoading: false,
				errorMsg: cognitoError(err)
			});
		}
	};

	renderSuccess() {
		return (
			<Alert color="success">
				Your password has been successfully reset.{' '}
				<a href="/" class="alert-link">
					Please log in
				</a>{' '}
				using your new credentials.
			</Alert>
		);
	}

	renderConfirmationForm() {
		return (
			<Form onSubmit={this.handleConfirmationSubmit}>
				<FormGroup>
					<Label for="confirmationCode">Verification code</Label>
					<span className="help-block small font-italic text-muted ml-3">
						Please check your email for the verification code.
					</span>
					<Input
						id="confirmationCode"
						placeholder="Enter verification code"
						autoFocus
						value={this.state.confirmationCode}
						onChange={this.handleChange}
					/>
				</FormGroup>
				<Row>
					<Col>
						<FormGroup>
							<Label for="password">New password</Label>
							<Input
								id="password"
								type="password"
								placeholder="Enter new password"
								value={this.state.password}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label for="confirmPassword">Confirm new password</Label>
							<Input
								id="confirmPassword"
								type="password"
								placeholder="Enter new password confirmation"
								value={this.state.confirmPassword}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<p className="help-block small text-muted font-italic">
					A password must be more than 7 characters and include an uppercase
					letter, a lowercase letter, a special character and a number
				</p>
				{this.state.errorMsg && (
					<Alert color="warning" className="py-2 px-3">
						{this.state.errorMsg}
					</Alert>
				)}
				<LoaderButton
					block
					color="primary"
					disabled={!this.validateConfirmationForm()}
					type="submit"
					isLoading={this.state.isLoading}
					text="Update password"
					loadingText="Updating…"
				/>
			</Form>
		);
	}

	renderForm() {
		return (
			<Form onSubmit={this.handleSubmit}>
				<p>
					Begin resetting your password by entering your username or email
					address. You will require access to your registered email account to
					complete the password reset.
				</p>
				<FormGroup>
					<Label for="username">Username or Email</Label>
					<Input
						id="username"
						placeholder="Enter username or email address"
						autoFocus
						value={this.state.username}
						onChange={this.handleChange}
					/>
				</FormGroup>
				{this.state.errorMsg && (
					<Alert color="warning" className="py-2 px-3">
						{this.state.errorMsg}
					</Alert>
				)}
				<LoaderButton
					block
					color="primary"
					disabled={!this.validateForm()}
					type="submit"
					isLoading={this.state.isLoading}
					text="Send verification code"
					loadingText="Sending…"
				/>
			</Form>
		);
	}

	render() {
		return (
			<DocumentTitle title="SAVANT | Sign up">
				<BodyClassName className="bg-dark">
					<Container fluid>
						<Card className="card-register mx-auto mt-5">
							<CardHeader className="card-logo-header">
								<ReactSVG
									src={earthiLogo}
									className="card-logo"
									svgClassName="earthi-card-logo"
								/>
								<ReactSVG
									src={savantLogo}
									className="card-logo"
									svgClassName="savant-card-logo"
								/>
								<h1 className="sr-only">SAVANT</h1>
							</CardHeader>
							<CardBody>
								{this.state.confirmResponse === null && this.renderForm()}
								{this.state.confirmResponse !== null &&
									!this.state.resetSuccess &&
									this.renderConfirmationForm()}
								{this.state.confirmResponse !== null &&
									this.state.resetSuccess &&
									this.renderSuccess()}
								<div className="text-center">
									<a className="d-block small mt-3" href="/">
										Log in
									</a>
								</div>
							</CardBody>
						</Card>
					</Container>
				</BodyClassName>
			</DocumentTitle>
		);
	}
}
