import { API } from 'aws-amplify';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { runAsyncTasks } from '../../utils';

import {
	getAuthHeaders,
	FETCH_STEEL_SMELTER_ALL_MAP_DATA,
	FETCH_STEEL_SMELTER_ALL_MAP_DATA_ERR,
	FETCH_STEEL_SMELTER_ALL_TIME_BY_SMELTER_DATA,
	FETCH_STEEL_SMELTER_ALL_TIME_BY_SMELTER_DATA_ERR,
	FETCH_STEEL_SMELTER_DATA_INIT,
	FETCH_STEEL_SMELTER_DATA,
	FETCH_STEEL_SMELTER_DATA_ERR,
	RESET_STEEL_SMELTER_DATA,
	FETCH_STEEL_SMELTER_CAPACITY_INIT,
	FETCH_STEEL_SMELTER_CAPACITY,
	FETCH_STEEL_SMELTER_CAPACITY_ERR,
	RESET_STEEL_SMELTER_CAPACITY,
	FETCH_STEEL_SMELTER_PRODUCTION_INIT,
	FETCH_STEEL_SMELTER_PRODUCTION,
	FETCH_STEEL_SMELTER_PRODUCTION_ERR,
	RESET_STEEL_SMELTER_PRODUCTION,
	FETCH_STEEL_SMELTER_PRODUCTION_SUBSITES_INIT,
	FETCH_STEEL_SMELTER_PRODUCTION_SUBSITES,
	FETCH_STEEL_SMELTER_PRODUCTION_SUBSITES_ERR,
	RESET_STEEL_SMELTER_PRODUCTION_SUBSITES,
	FETCH_STEEL_SMELTER_CO2_EMISSIONS_INIT,
	FETCH_STEEL_SMELTER_CO2_EMISSIONS,
	FETCH_STEEL_SMELTER_CO2_EMISSIONS_ERR,
	RESET_STEEL_SMELTER_CO2_EMISSIONS,
	FETCH_STEEL_SMELTER_LIST,
	FETCH_STEEL_SMELTER_LIST_ERR,
	FETCH_STEEL_SMELTERS_STATE,
	FETCH_STEEL_SMELTERS_STATE_ERR,
	FETCH_STEEL_SMELTER_RECENT,
	FETCH_STEEL_SMELTER_RECENT_ERR,
	FETCH_STEEL_SMELTER_RECENT_UPDATES,
	FETCH_STEEL_SMELTER_RECENT_UPDATES_ERR,
	FETCH_STEEL_SMELTER_REGIONS,
	FETCH_STEEL_SMELTER_REGIONS_ERR,
	FETCH_STEEL_SMELTER_UPDATES,
	FETCH_STEEL_SMELTER_UPDATES_ERR,
	// RESET_STEEL_SMELTER_MAP_DATA,
	SELECT_STEEL_SMELTER,
	UNSELECT_STEEL_SMELTER
} from '../';
import config from '../../config';
import { replacer } from '../../utils';

export const fetchSteelSmelterData =
	({ grouping, ids, plant, dateFrom: startDate = '', dateTo: endDate = '' }) =>
	async (dispatch) => {
		console.log('grouping', grouping);
		const timestamp = Date.now();
		dispatch({ type: FETCH_STEEL_SMELTER_DATA_INIT, payload: { timestamp } });
		const request = API.get(
			'gains-api',
			replacer(config.apiEndpoints.steelSmelterData, {
				grouping,
				ids,
				plant,
				startDate,
				endDate
			}),
			{
				headers: {}
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_STEEL_SMELTER_DATA,
					payload: { data: response, timestamp }
				});
			},
			(err) =>
				dispatch({
					type: FETCH_STEEL_SMELTER_DATA_ERR,
					payload: { error: err, timestamp }
				})
		);
	};

export const resetSteelSmelterData = () => (dispatch) =>
	dispatch({ type: RESET_STEEL_SMELTER_DATA });

export const fetchSteelSmelterCapacity =
	({ grouping, ids, plant, dateFrom: startDate = '', dateTo: endDate = '' }) =>
	async (dispatch) => {
		const timestamp = Date.now();
		dispatch({
			type: FETCH_STEEL_SMELTER_CAPACITY_INIT,
			payload: { timestamp }
		});
		const request = API.get(
			'gains-api',
			replacer(config.apiEndpoints.steelSmelterCapacity, {
				grouping,
				ids,
				plant,
				startDate,
				endDate
			}),
			{
				headers: {}
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_STEEL_SMELTER_CAPACITY,
					payload: { data: response, timestamp }
				});
			},
			(err) =>
				dispatch({
					type: FETCH_STEEL_SMELTER_CAPACITY_ERR,
					payload: err
				})
		);
	};

export const resetSteelSmelterCapacity = () => (dispatch) =>
	dispatch({ type: RESET_STEEL_SMELTER_CAPACITY });

export const fetchSteelSmelterProduction =
	({
		grouping,
		ids,
		collapse,
		plant,
		dateFrom: startDate = '',
		dateTo: endDate = ''
	}) =>
	async (dispatch) => {
		const timestamp = Date.now();
		dispatch({
			type: FETCH_STEEL_SMELTER_PRODUCTION_INIT,
			payload: { timestamp }
		});
		const request = API.get(
			'gains-api',
			replacer(config.apiEndpoints.steelSmelterProduction, {
				grouping,
				ids,
				collapse,
				plant,
				startDate,
				endDate
			}),
			{
				headers: {}
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_STEEL_SMELTER_PRODUCTION,
					payload: { data: response, timestamp }
				});
			},
			(err) =>
				dispatch({
					type: FETCH_STEEL_SMELTER_PRODUCTION_ERR,
					payload: err
				})
		);
	};

export const fetchCsvSteelProductionData = async ({
	grouping,
	ids,
	plantTypes,
	csvAllPlantTypes,
	dateFrom,
	dateTo
}) => {
	const csvPlantTypes = csvAllPlantTypes ? ['bfbof', 'eaf'] : plantTypes;
	const task_type_default = ({ collapse, startDate, endDate }) => ({
		name: collapse,
		task: () =>
			API.get(
				'gains-api',
				replacer(config.apiEndpoints.steelSmelterProduction, {
					grouping,
					ids,
					collapse,
					plant: '',
					startDate: startDate.format('YYYY-MM-DD'),
					endDate: endDate.format('YYYY-MM-DD')
				}),
				{
					headers: {}
				}
			)
	});

	const task_type_0 = ({ collapse, startDate, endDate }) => ({
		name: `${collapse}_${csvPlantTypes[0]}`,
		task: () =>
			API.get(
				'gains-api',
				replacer(config.apiEndpoints.steelSmelterProduction, {
					grouping,
					ids,
					collapse,
					plant: csvPlantTypes[0],
					startDate: startDate.format('YYYY-MM-DD'),
					endDate: endDate.format('YYYY-MM-DD')
				}),
				{
					headers: {}
				}
			)
	});

	const task_type_1 = ({ collapse, startDate, endDate }) => ({
		name: `${collapse}_${csvPlantTypes[1]}`,
		task: () =>
			API.get(
				'gains-api',
				replacer(config.apiEndpoints.steelSmelterProduction, {
					grouping,
					ids,
					collapse,
					plant: csvPlantTypes[1],
					startDate: startDate.format('YYYY-MM-DD'),
					endDate: endDate.format('YYYY-MM-DD')
				}),
				{
					headers: {}
				}
			)
	});

	const collapses = ['daily', 'weekly', 'monthly', 'annual'];
	const tasks = [];
	let startDate = moment.utc(dateFrom);
	const dateRanges = [];
	while (startDate.isBefore(dateTo)) {
		let endDate = startDate
			.clone()
			.add(2, 'y')
			.startOf('year')
			.subtract(1, 'd');
		if (endDate.isAfter(dateTo)) {
			endDate = moment.utc(dateTo);
		}
		dateRanges.push({ startDate, endDate });
		startDate = endDate.clone().add(1, 'd');
	}
	dateRanges.forEach(({ startDate, endDate }) =>
		collapses.forEach((collapse) => {
			if (csvPlantTypes.length === 0 || csvPlantTypes.length === 2) {
				tasks.push(task_type_default({ collapse, startDate, endDate }));
				if (csvPlantTypes.length) {
					tasks.push(
						...[
							task_type_0({ collapse, startDate, endDate }),
							task_type_1({ collapse, startDate, endDate })
						]
					);
				}
			} else {
				tasks.push(task_type_0({ collapse, startDate, endDate }));
			}
		})
	);

	try {
		return await runAsyncTasks(tasks, 1);
	} catch (error) {
		toastr.error(`Download production data, ${error} `);
		return [];
	}
};

export const fetchSteelSmelterProductionSubsites =
	({
		grouping,
		ids,
		subsiteType,
		collapse,
		dateFrom: startDate = '',
		dateTo: endDate = ''
	}) =>
	async (dispatch) => {
		const timestamp = Date.now();
		dispatch({
			type: FETCH_STEEL_SMELTER_PRODUCTION_SUBSITES_INIT,
			payload: { timestamp }
		});
		const request = API.get(
			'gains-api',
			replacer(config.apiEndpoints.steelSmelterProductionSubsites, {
				grouping,
				ids,
				subsiteType,
				collapse,
				startDate,
				endDate
			}),
			{
				headers: {}
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_STEEL_SMELTER_PRODUCTION_SUBSITES,
					payload: { data: response, timestamp }
				});
			},
			(err) =>
				dispatch({
					type: FETCH_STEEL_SMELTER_PRODUCTION_SUBSITES_ERR,
					payload: err
				})
		);
	};

export const fetchCsvSteelProductionDataSubsites = async ({
	grouping,
	ids,
	sType,
	dateFrom,
	dateTo
}) => {
	const task_type_default = ({ collapse, startDate, endDate }) => ({
		name: collapse,
		task: () =>
			API.get(
				'gains-api',
				replacer(config.apiEndpoints.steelSmelterProductionSubsites, {
					grouping,
					ids,
					sType,
					collapse,
					startDate: startDate.format('YYYY-MM-DD'),
					endDate: endDate.format('YYYY-MM-DD')
				}),
				{
					headers: {}
				}
			)
	});

	const collapses = ['daily', 'weekly', 'monthly', 'annual'];
	const tasks = [];
	let startDate = moment.utc(dateFrom);
	const dateRanges = [];
	while (startDate.isBefore(dateTo)) {
		let endDate = startDate
			.clone()
			.add(2, 'y')
			.startOf('year')
			.subtract(1, 'd');
		if (endDate.isAfter(dateTo)) {
			endDate = moment.utc(dateTo);
		}
		dateRanges.push({ startDate, endDate });
		startDate = endDate.clone().add(1, 'd');
	}
	dateRanges.forEach(({ startDate, endDate }) =>
		collapses.forEach((collapse) => {
			tasks.push(task_type_default({ collapse, startDate, endDate }));
		})
	);
	try {
		return await runAsyncTasks(tasks, 1);
	} catch (error) {
		toastr.error(`Download production data, ${error} `);
		return [];
	}
};

export const fetchSteelSmelterCO2Emissions =
	({
		grouping,
		ids,
		collapse,
		plant,
		dateFrom: startDate = '',
		dateTo: endDate = ''
	}) =>
	async (dispatch) => {
		const timestamp = Date.now();
		dispatch({
			type: FETCH_STEEL_SMELTER_CO2_EMISSIONS_INIT,
			payload: { timestamp }
		});
		const request = API.get(
			'gains-api',
			replacer(config.apiEndpoints.steelSmelterCO2Emissions, {
				grouping,
				ids,
				collapse,
				plant,
				startDate,
				endDate
			}),
			{
				headers: {}
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_STEEL_SMELTER_CO2_EMISSIONS,
					payload: { data: response, timestamp }
				});
			},
			(err) =>
				dispatch({
					type: FETCH_STEEL_SMELTER_CO2_EMISSIONS_ERR,
					payload: err
				})
		);
	};

export const fetchCsvSteelCO2EmissionsData = async ({
	grouping,
	ids,
	plantTypes,
	csvAllPlantTypes,
	dateFrom,
	dateTo
}) => {
	const csvPlantTypes = csvAllPlantTypes ? ['bfbof', 'eaf'] : plantTypes;
	const task_type_default = ({ collapse, startDate, endDate }) => ({
		name: collapse,
		task: () =>
			API.get(
				'gains-api',
				replacer(config.apiEndpoints.steelSmelterCO2Emissions, {
					grouping,
					ids,
					collapse,
					plant: '',
					startDate: startDate.format('YYYY-MM-DD'),
					endDate: endDate.format('YYYY-MM-DD')
				}),
				{
					headers: {}
				}
			)
	});

	const task_type_0 = ({ collapse, startDate, endDate }) => ({
		name: `${collapse}_${csvPlantTypes[0]}`,
		task: () =>
			API.get(
				'gains-api',
				replacer(config.apiEndpoints.steelSmelterCO2Emissions, {
					grouping,
					ids,
					collapse,
					plant: csvPlantTypes[0],
					startDate: startDate.format('YYYY-MM-DD'),
					endDate: endDate.format('YYYY-MM-DD')
				}),
				{
					headers: {}
				}
			)
	});

	const task_type_1 = ({ collapse, startDate, endDate }) => ({
		name: `${collapse}_${csvPlantTypes[1]}`,
		task: () =>
			API.get(
				'gains-api',
				replacer(config.apiEndpoints.steelSmelterCO2Emissions, {
					grouping,
					ids,
					collapse,
					plant: csvPlantTypes[1],
					startDate: startDate.format('YYYY-MM-DD'),
					endDate: endDate.format('YYYY-MM-DD')
				}),
				{
					headers: {}
				}
			)
	});

	const collapses = ['daily', 'weekly', 'monthly', 'annual'];
	const tasks = [];
	let startDate = moment.utc(dateFrom);
	const dateRanges = [];
	while (startDate.isBefore(dateTo)) {
		let endDate = startDate
			.clone()
			.add(2, 'y')
			.startOf('year')
			.subtract(1, 'd');
		if (endDate.isAfter(dateTo)) {
			endDate = moment.utc(dateTo);
		}
		dateRanges.push({ startDate, endDate });
		startDate = endDate.clone().add(1, 'd');
	}
	dateRanges.forEach(({ startDate, endDate }) =>
		collapses.forEach((collapse) => {
			if (csvPlantTypes.length === 0 || csvPlantTypes.length === 2) {
				tasks.push(task_type_default({ collapse, startDate, endDate }));
				if (csvPlantTypes.length) {
					tasks.push(
						...[
							task_type_0({ collapse, startDate, endDate }),
							task_type_1({ collapse, startDate, endDate })
						]
					);
				}
			} else {
				tasks.push(task_type_0({ collapse, startDate, endDate }));
			}
		})
	);

	try {
		return await runAsyncTasks(tasks, 1);
	} catch (error) {
		toastr.error(`Download CO2 emissions data, ${error} `);
		return [];
	}
};

export const resetSteelSmelterProduction = () => (dispatch) =>
	dispatch({ type: RESET_STEEL_SMELTER_PRODUCTION });

export const resetSteelSmelterProductionSubsites = () => (dispatch) =>
	dispatch({ type: RESET_STEEL_SMELTER_PRODUCTION_SUBSITES });

export const resetSteelSmelterCO2Emissions = () => (dispatch) =>
	dispatch({ type: RESET_STEEL_SMELTER_CO2_EMISSIONS });

export const fetchWorldSteelData = async ({
	grouping,
	id,
	collapse,
	dateFrom: startDate = '',
	dateTo: endDate = ''
}) => {
	return await API.get(
		'gains-api',
		replacer(config.apiEndpoints.worldSteelData, {
			grouping,
			id,
			collapse,
			startDate,
			endDate
		}),
		{
			headers: {}
		}
	);
};

export const fetchWorldSteelRegions = async ({ subsiteType }) => {
	return await API.get(
		'gains-api',
		replacer(config.apiEndpoints.worldSteelRegions, {
			subsiteType
		}),
		{
			headers: {}
		}
	);
};
export const fetchWorldSteelSubsitesData = async ({
	grouping,
	id,
	subsiteType,
	collapse,
	dateFrom: startDate = '',
	dateTo: endDate = ''
}) => {
	return await API.get(
		'gains-api',
		replacer(config.apiEndpoints.worldSteelSubsitesData, {
			grouping,
			id,
			subsiteType,
			collapse,
			startDate,
			endDate
		}),
		{
			headers: {}
		}
	);
};

export const fetchSteelSmelterAllMapData = () => async (dispatch) => {
	const request = API.get(
		'savant-api',
		config.apiEndpoints.steelSmelterAllMapData,
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_STEEL_SMELTER_ALL_MAP_DATA,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_STEEL_SMELTER_ALL_MAP_DATA_ERR,
				payload: err
			})
	);
};

export const fetchSteelSmelterHotspotsAllTime =
	({ smelterIds }) =>
	async (dispatch) => {
		const request = API.get(
			'gains-api',
			replacer(config.apiEndpoints.steelSmelterAllTimeBySmelter, {
				smelterIds
			}),
			{
				headers: {}
			}
		);

		return request.then(
			(response) =>
				dispatch({
					type: FETCH_STEEL_SMELTER_ALL_TIME_BY_SMELTER_DATA,
					payload: response
				}),
			(err) =>
				dispatch({
					type: FETCH_STEEL_SMELTER_ALL_TIME_BY_SMELTER_DATA_ERR,
					payload: err
				})
		);
	};

export const resetSteelSmelterHotspotsAllTime = () => ({
	type: FETCH_STEEL_SMELTER_ALL_TIME_BY_SMELTER_DATA,
	payload: {}
});

export const fetchSteelSmeltersState =
	({ grouping, ids, plantType, searchDate }) =>
	async (dispatch) => {
		const request = API.get(
			'gains-api',
			replacer(config.apiEndpoints.steelSmeltersStatus, {
				grouping,
				ids,
				plantType,
				searchDate
			}),

			{
				headers: {}
			}
		);

		return request.then(
			(response) =>
				dispatch({
					type: FETCH_STEEL_SMELTERS_STATE,
					payload: response
				}),
			(err) =>
				dispatch({
					type: FETCH_STEEL_SMELTERS_STATE_ERR,
					payload: err
				})
		);
	};

export const resetSteelSmeltersState = () => ({
	type: FETCH_STEEL_SMELTERS_STATE,
	payload: []
});

export const fetchSteelSmelterList = () => async (dispatch) => {
	const request = API.get('savant-api', config.apiEndpoints.steelSmelterList, {
		headers: await getAuthHeaders()
	});

	return request.then(
		(response) => {
			dispatch({
				type: FETCH_STEEL_SMELTER_LIST,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_STEEL_SMELTER_LIST_ERR,
				payload: err
			})
	);
};

export const fetchSteelSmelterRecent = () => async (dispatch) => {
	const request = API.get(
		'gains-api',
		config.apiEndpoints.steelSmelterRecent,
		{}
	);
	return request.then(
		(response) => {
			dispatch({
				type: FETCH_STEEL_SMELTER_RECENT,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_STEEL_SMELTER_RECENT_ERR,
				payload: err
			})
	);
};

export const fetchSteelSmelterRecentUpdates = () => async (dispatch) => {
	const request = API.get(
		'savant-api',
		config.apiEndpoints.steelSmelterRecentUpdates,
		{
			headers: await getAuthHeaders()
		}
	);
	return request.then(
		(response) => {
			dispatch({
				type: FETCH_STEEL_SMELTER_RECENT_UPDATES,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_STEEL_SMELTER_RECENT_UPDATES_ERR,
				payload: err
			})
	);
};

export const fetchSteelSmelterRegions = () => async (dispatch) => {
	const request = API.get(
		'savant-api',
		config.apiEndpoints.steelSmelterRegions,
		{
			headers: await getAuthHeaders()
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_STEEL_SMELTER_REGIONS,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_STEEL_SMELTER_REGIONS_ERR,
				payload: err
			})
	);
};

export const fetchSteelSmelterUpdates =
	({ regions, dateFrom: startDate = '', dateTo: endDate = '' }) =>
	async (dispatch) => {
		const request = API.get(
			'savant-api',
			replacer(config.apiEndpoints.steelSmelterUpdates, {
				regions,
				startDate,
				endDate
			}),
			{
				headers: await getAuthHeaders()
			}
		);
		return request.then(
			(response) => {
				dispatch({
					type: FETCH_STEEL_SMELTER_UPDATES,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_STEEL_SMELTER_UPDATES_ERR,
					payload: err
				})
		);
	};

export const resetSteelSmelterUpdates = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_STEEL_SMELTER_UPDATES, payload: [] });
	};
};

export const selectSteelSmelter = (id) => ({
	type: SELECT_STEEL_SMELTER,
	payload: id
});

export const unselectSteelSmelter = (id) => ({
	type: UNSELECT_STEEL_SMELTER,
	payload: id
});
