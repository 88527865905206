import { FETCH_SCRAP_STEEL_DATA_BY_SITE } from '../../actions';

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_SCRAP_STEEL_DATA_BY_SITE:
			return action.payload;
		default:
			return state;
	}
};
