import React, { Fragment } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Navigation, Footer } from 'containers';

export default ({
	component: C,
	props: cProps,
	noTemplate = false,
	insecure = false,
	groupProtect = [],
	type = 'cu',
	alias = '',
	...rest
}) => {
	const groupGuardAllow =
		groupProtect.length === 0 ||
		groupProtect.some((r) => cProps.authGroups.includes(r));
	if (!cProps.isAuthenticated && !insecure) {
		console.error('Not logged in; access denied to secured route');
		return (
			<Route
				{...rest}
				render={(props) => (
					<Redirect to={{ pathname: '/', state: { from: props.location } }} />
				)}
			/>
		);
	} else if (!groupGuardAllow) {
		console.error(
			'Insufficient group privileges; access denied to protected route'
		);

		return (
			<Route
				{...rest}
				render={(props) => (
					<Redirect
						to={{ pathname: '/home', state: { from: props.location } }}
					/>
				)}
			/>
		);
	}

	return (
		<Route
			{...rest}
			render={(props) => (
				<Fragment>
					{!noTemplate && (
						<Navigation {...props} {...cProps} type={type} alias={alias} />
					)}
					<C {...props} {...cProps} />
					{!noTemplate && <Footer />}
				</Fragment>
			)}
		/>
	);
};
