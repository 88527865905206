import config from 'config';

export const replacer = (template, obj) => {
	var keys = Object.keys(obj);
	var func = Function(...keys, 'return `' + template + '`;'); // eslint-disable-line no-new-func
	const replaced = func(...keys.map((k) => obj[k]));
	return replaced.replace(/([^a-zA-Z0-9])\1+/gm, '$1'); // remove consecutive special charactes duplicates
	// return func(...keys.map((k) => obj[k]));
};

export async function runAsyncTasks(tasks, concurrency) {
	let results = [];
	// let results = {};
	async function runTasks(tasksIterator) {
		// eslint-disable-next-line no-unused-vars
		for (const [index, { name, task }] of tasksIterator) {
			try {
				results = [...results, { [name]: await task() }];
				// results[index] = { [name]: await task() };
			} catch (error) {
				throw new Error(`Failed with: ${error.message}`);
			}
		}
	}
	const workers = new Array(concurrency).fill(tasks.entries()).map(runTasks);

	// await Promise.allSettled(workers);
	await Promise.all(workers);
	return results;
}

export const zipArrays = (...arrs) =>
	arrs.reduce((a, arr) => {
		arr.forEach((x, i) => Object.assign((a[i] = a[i] || {}), x));
		return a;
	}, []);

//find unique objects in an array
export const getUniqueObjects = (arr, key) => {
	return [...new Map(arr.map((item) => [item[key], item])).values()];
};

//merge two arrays of objects
export const mergeArrays = (arr1, arr2) =>
	arr1.reduce((m, a1, i) => [...m, { ...a1, ...arr2[i] }], []);

//in an array merge arrays inside object
// export const mergeObjectsBasedOnKey = (array) => {
// 	console.log('array', array);
// 	const subarrsById = {};
// 	for (const { name, data } of array) {
// 		if (!subarrsById[name]) {
// 			subarrsById[name] = { name, data: [...data] };
// 			// subarrsById[name] = { data: [...data] };
// 		} else {
// 			subarrsById[name].data.push(...data);
// 		}
// 	}
// 	// console.log('subbarrays', subarrsById);
// 	return Object.values(subarrsById);
// };

export const mergeSubArraysBasedOnKey = (array, key) =>
	array.reduce((arr, obj) => {
		const objKeys = Object.keys(obj);
		const mainKey = objKeys.find((objKey) => objKey === `${key}`);
		const subArrKey = objKeys.find((objKey) => objKey !== `${key}`);
		const index = arr.findIndex((item) => item[`${key}`] === obj[`${key}`]);
		if (index >= 0) {
			arr[index][subArrKey] = [...arr[index][subArrKey], ...obj[subArrKey]];
		} else {
			arr.push({
				[`${key}`]: obj[mainKey],
				[`${subArrKey}`]: obj[subArrKey]
			});
		}
		return arr;
	}, []);

export const capitaliseAll = (alphanumeric) => {
	const words = alphanumeric.split(' ');

	return words
		.map((word) => {
			return word[0].toUpperCase() + word.substring(1);
		})
		.join(' ');
};

//everything not alphanumeric will be replaced with space
export const getAlphaNumeric = (string) => {
	return string.replace(/[^a-z0-9]+/gi, ' ');
};

export const jsDate = (momentObj) =>
	new Date(momentObj.format('YYYY-MM-DDTHH:mm:ss'));

const queryCheck = (s) => document.createDocumentFragment().querySelector(s);

export const isSelectorValid = (selector) => {
	try {
		queryCheck(selector);
	} catch (err) {
		return false;
	}
	return true;
};

export const generateRandomColours = (count) => {
	const colours = Array(count).fill(0);
	return colours.map(
		(c) =>
			`#${Math.floor(Math.random() * 16777215)
				.toString(16)
				.padStart(6, '0')}`
	);
};

//group array of objects by property
export const groupByKey = (input, key) =>
	input.reduce((acc, currentValue) => {
		let groupKey = currentValue[key];

		if (!acc[groupKey]) {
			acc[groupKey] = [];
		}

		acc[groupKey].push(currentValue);
		return acc;
	}, {});

export const flattenObject = (ob) => {
	let result = {};
	for (const i in ob) {
		if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
			const temp = flattenObject(ob[i]);
			for (const j in temp) {
				result[j] = temp[j];
			}
		} else {
			result[i] = ob[i];
		}
	}
	return result;
};

/*cognito user groups functions*/
//checks if user is currently logged with groupType rights for given type of metal
//groupType: 'free', 'enterprise', etc.; givenTypes: 'cu' or 'ni' or 'cu, ni' or ''-which means all metal types
//all metal types should be defined through config
const isRequestedGroupType = (userGroups, groupType, givenTypes) => {
	const { cognitoGroups, allTypes } = config;
	const mTypes = givenTypes ? givenTypes.split(',') : [];
	const types = mTypes.length ? mTypes : allTypes;
	let includes = [];
	let excludes = [];
	types.forEach((type) => {
		includes = [
			...includes,
			cognitoGroups[`${groupType}`][`${type.trim()}`].required
		];
		excludes = [
			...excludes,
			...cognitoGroups[`${groupType}`][`${type.trim()}`].excludes
		];
	});
	const exlGroups = userGroups.filter((ug) => !includes.includes(ug));
	return (
		userGroups.length !== exlGroups.length &&
		!exlGroups.some((eg) => excludes.includes(eg))
	);
};

export const isUserFree = (userGroups, type = '') =>
	isRequestedGroupType(userGroups, 'free', type);
export const isUserPremium = (userGroups, type = '') =>
	isRequestedGroupType(userGroups, 'premium', type);
export const isUserPremiumPlus = (userGroups, type = '') =>
	isRequestedGroupType(userGroups, 'premiumPlus', type);
export const isUserSales = (userGroups, type = '') =>
	isRequestedGroupType(userGroups, 'sales', type);
export const isUserEnterprise = (userGroups, type = '') =>
	isRequestedGroupType(userGroups, 'enterprise', type);
export const isUserEnterpriseOrSales = (userGroups, type = '') =>
	isUserSales(userGroups, type) || isUserEnterprise(userGroups, type);

//returns all groups user can belong to for specific type of metal
//given type has to be specific ('ni' or 'cu' etc.); given group types can contain multiple cognito levels
//e.g. 'sales, free' or 'enterprise' etc. If no group types are given all groups are considered
export const getGroupsPerType = (type, givenGroupTypes = '') => {
	const { cognitoGroups } = config;
	const allGroups = Object.keys(cognitoGroups);
	const groupTypes = (
		givenGroupTypes ? givenGroupTypes.split(',') : allGroups
	).map((gt) => gt.trim());
	const resultGroups = [];
	allGroups.forEach((g) => {
		if (groupTypes.includes(g)) {
			resultGroups.push(cognitoGroups[g][`${type}`].required);
		}
	});
	return resultGroups;
};

export const getGroupsSalesEnterprise = (type) =>
	getGroupsPerType(type, 'sales, enterprise');

export const getGroupsPremiumHigher = (type) =>
	getGroupsPerType(type, 'premiumPlus, sales, enterprise');

//get all types of metal which user has on some cognito level
const getUserGroupTypes = (userGroups, groupType) => {
	const { allTypes } = config;
	const types = [];
	allTypes.forEach((type) => {
		if (isRequestedGroupType(userGroups, groupType, type)) {
			types.push(type);
		}
	});
	return types;
};

export const getEnterpriseTypes = (userGroups) =>
	getUserGroupTypes(userGroups, 'enterprise');

export const getSalesTypes = (userGroups) =>
	getUserGroupTypes(userGroups, 'sales');

export const getPremiumPlusTypes = (userGroups) =>
	getUserGroupTypes(userGroups, 'premiumPlus');

export const getPremiumTypes = (userGroups) =>
	getUserGroupTypes(userGroups, 'premium');

export const getSalesOrEnterpriseTypes = (userGroups) => [
	...new Set([...getSalesTypes(userGroups), ...getEnterpriseTypes(userGroups)])
];

//if user belongs at least to one group with rights greater than given groupType for specific metal type
const isUserHigherThan = (userGroups, groupType, type) => {
	const { cognitoGroups } = config;
	const allGroups = Object.keys(cognitoGroups);
	const allHigherGroupTypes = allGroups
		.filter((key) => key !== groupType)
		.join();
	const inclGroups = getGroupsPerType(type, allHigherGroupTypes);
	return userGroups.some((ug) => inclGroups.includes(ug));
};

export const isUserHigherThanFree = (userGroups, type) =>
	isUserHigherThan(userGroups, 'free', type);

//returns all possible groups user can have excluding 'free' for all types of metal
export const getSecuredGroups = () => {
	const { cognitoGroups, allTypes } = config;
	let sGroups = [];
	allTypes.forEach((type) => {
		sGroups.push(...cognitoGroups.free[`${type}`].excludes);
	});
	return sGroups;
};
