import React from 'react';
import { Marker } from 'react-map-gl';

const SiteMarker = ({
	selectedId,
	site,
	openPopup,
	displayMarker
	// activePopupId
}) => {
	const { id, lon, lat, state, eDate, highlight } = site;
	// if (activePopupId !== id) {
	return (
		<Marker longitude={lon} latitude={lat}>
			<div
				id={id}
				className={`marker ${
					displayMarker
						? `marker-${selectedId === id ? 'active' : 'inactive'}`
						: ''
				}`}
			>
				{displayMarker && (
					<div
						className={`circle + ${
							eDate ? 'shutdown' : state === 1 ? 'active' : 'inactive'
						} ${highlight ? 'highlight' : ''}`}
						onClick={() => openPopup(id)}
					/>
				)}
			</div>
		</Marker>
	);
	// } else {
	// 	console.log('activePopupId', activePopupId);

	// 	return (
	// 		<Marker
	// 			longitude={lon}
	// 			latitude={lat}
	// 			draggable={true}
	// 			// onDrag={({ target }) => dragMarker(target.getLngLat())}
	// 			onDrag={({ target }) =>
	// 				console.log(
	// 					'target',
	// 					target._map._popups[0].setLngLat(target.getLngLat())
	// 				)
	// 			}
	// 		>
	// 			<div
	// 				style={{
	// 					width: '1.5rem',
	// 					height: '1.5rem',
	// 					borderRadius: '50%',
	// 					backgroundColor: 'white',
	// 					paddingTop: '0.1rem'
	// 				}}
	// 			>
	// 				<div id={id} className="text-center">
	// 					<i className="fa fa-arrows fa-lg" aria-hidden="true"></i>
	// 				</div>
	// 			</div>
	// 		</Marker>
	// 	);
	// }
};

export default SiteMarker;
