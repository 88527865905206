import { FETCH_SITES } from '../actions';
import { flattenObject } from '../utils';

export default (state = [], action) => {
	switch (action.type) {
		case FETCH_SITES:
			const sites = action.payload.map((p) => flattenObject(p));
			return sites;
		default:
			return state;
	}
};
