import { FETCH_GROUPS, RESET_GROUPS } from '../actions';
import { groupByKey } from '../utils';

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_GROUPS:
			const groupsByType = groupByKey(action.payload, 'group_type');
			return { ...state, ...groupsByType };
		case RESET_GROUPS:
			return {};
		default:
			return state;
	}
};
