import { FETCH_SCRAP_STEEL_DATA } from '../../actions';

export default (state = [], action) => {
	switch (action.type) {
		case FETCH_SCRAP_STEEL_DATA:
			return action.payload;
		default:
			return state;
	}
};
