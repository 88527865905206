import React, { Component, Fragment } from 'react';
import {
	Alert,
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Form,
	FormGroup,
	FormText,
	Input,
	InputGroup,
	InputGroupAddon,
	Label
} from 'reactstrap';
import { Auth } from 'aws-amplify';
import BodyClassName from 'react-body-classname';
import DocumentTitle from 'react-document-title';
import ReactSVG from 'react-svg';
import ReactGA from 'react-ga4';
import { Flag } from 'flag';
import QRCodeSVG from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { cognitoError, LoaderButton } from 'components';
import { Mailto } from './Mailto';
import { isSelectorValid } from '../../utils';
import colours from '../../_colours.scss';
import earthiLogo from 'images/earthi.svg';
import gainsLogo from 'images/gains-logo-dark.svg';
import esaLogo from 'images/esa.png';
import navigateLogo from 'images/navigate_commodities.png';
// import marexSpectronLogo from 'images/marex_spectron.png';

export default class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isAutofill: false,
			isVerifying: false,
			user: '',
			email: '',
			password: '',
			challengeName: '',
			mfaCode: '',
			challengeCode: '',
			showPassword: false,
			copied: false,
			errorMsg: ''
		};

		this.unRef = React.createRef();
		this.pwdRef = React.createRef();
	}

	componentDidMount() {
		// ReactGA.pageview('/', null, 'Login');
		isSelectorValid(':-internal-autofill-selected') &&
			setTimeout(() => {
				if (
					this.unRef.current.matches(':-internal-autofill-selected') &&
					this.pwdRef.current.matches(':-internal-autofill-selected')
				) {
					this.setState({ isAutofill: true });
				}
			}, 650);
	}

	validateForm() {
		return this.state.email.length > 0 && this.state.password.length > 0;
	}

	handleChange = (event) => {
		const { isAutofill } = this.state;
		const newIsAutofill = isAutofill ? !isAutofill : isAutofill;
		this.setState({
			[event.target.id]: event.target.value,
			errorMsg: '',
			isAutofill: newIsAutofill
		});
	};

	handleSignIn = async (event) => {
		event.preventDefault();
		this.setState({
			isLoading: true
		});

		try {
			let userRole = '';
			const user = await Auth.signIn(this.state.email, this.state.password);
			let authUser = '';
			if (!user.challengeName) {
				authUser = await Auth.currentAuthenticatedUser();
				userRole = authUser.attributes['custom:userRole'];
			}
			//user has already set up MFA
			if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
				this.setState({
					challengeName: user.challengeName,
					user,
					isLoading: false
				});
				//user is admin and has not set up MFA yet
			} else if (userRole === 'admin' || userRole === 'superUser') {
				const code = await Auth.setupTOTP(user);
				this.setState({
					mfaCode: code,
					user,
					isLoading: false
				});
				//log in normally if user is not an admin
			} else {
				this.props.userHasAuthenticated(true, []);
				ReactGA.event({
					category: 'authentication',
					action: 'login',
					label: user?.attributes?.sub || ''
				});
				this.props.history.push('/steel/home');
			}
		} catch (err) {
			this.setState({
				isLoading: false,
				errorMsg: cognitoError(err)
			});
		}
	};

	handleMfaVerification = async (event) => {
		//this function fill be called only if mfaCode or challengeName==='SOFTWARE_TOKEN_MFA' is set
		event.preventDefault();
		const { user, mfaCode, challengeName, challengeCode } = this.state;

		this.setState({
			isVerifying: true
		});
		try {
			if (mfaCode) {
				await Auth.verifyTotpToken(user, challengeCode);
				await Auth.setPreferredMFA(user, 'TOTP');
				// const res = Auth.confirmSignIn(user, challengeCode, 'SOFTWARE_TOKEN_MFA');
			} else if (challengeName === 'SOFTWARE_TOKEN_MFA') {
				await Auth.confirmSignIn(user, challengeCode, 'SOFTWARE_TOKEN_MFA');
			}
			this.props.userHasAuthenticated(true, []);
			ReactGA.event({
				category: 'authentication',
				action: 'login',
				label: user?.attributes?.sub || ''
			});
			this.props.history.push('/steel/home');
		} catch (err) {
			this.setState({
				isVerifying: false,
				errorMsg: cognitoError(err)
			});
		}
	};

	toggleShowPassword = () =>
		this.setState({ showPassword: !this.state.showPassword });

	renderError() {
		const { errorMsg } = this.state;
		if (errorMsg)
			return (
				<Alert color="warning" className="py-2 px-3">
					{this.state.errorMsg}
				</Alert>
			);
	}

	renderSignInForm() {
		const { mfaCode, challengeName } = this.state;
		if (!mfaCode && !challengeName) {
			return (
				<Fragment>
					<Form onSubmit={this.handleSignIn}>
						<FormGroup>
							<Label for="email">Email</Label>
							<Input
								id="email"
								placeholder="Enter email address"
								autoFocus
								value={this.state.username}
								onChange={this.handleChange}
								innerRef={this.unRef}
							/>
						</FormGroup>
						<FormGroup>
							<Label for="password">Password</Label>
							<InputGroup>
								<Input
									id="password"
									type={this.state.showPassword ? 'text' : 'password'}
									placeholder="Enter password"
									value={this.state.password}
									onChange={this.handleChange}
									innerRef={this.pwdRef}
								/>
								<InputGroupAddon addonType="append">
									<Button
										outline
										color="secondary"
										onClick={this.toggleShowPassword}
									>
										<i
											className={
												this.state.showPassword
													? 'fa fa-eye fa-lg'
													: 'fa fa-eye-slash fa-lg'
											}
										/>
										<span className="sr-only">Toggle password display</span>
									</Button>
								</InputGroupAddon>
							</InputGroup>
						</FormGroup>
						{this.renderError()}
						<LoaderButton
							block
							color="primary"
							disabled={!this.state.isAutofill && !this.validateForm()}
							type="submit"
							isLoading={this.state.isLoading}
							text="Log in"
							loadingText="Logging in…"
						/>
					</Form>
					<div className="text-center mt-3">
						<Flag
							name="features.showSignup"
							render={() => (
								<Fragment>
									<a className="small" href="/signUp">
										Sign up
									</a>
									<span className="text-muted"> | </span>
								</Fragment>
							)}
						/>
						<a className="small" href="/forgotPassword">
							Forgot password
						</a>
					</div>
				</Fragment>
			);
		}
	}
	renderVerifyMfaForm() {
		const { mfaCode, challengeName, challengeCode, user } = this.state;
		if (mfaCode || challengeName === 'SOFTWARE_TOKEN_MFA') {
			return (
				<Form onSubmit={this.handleMfaVerification}>
					{mfaCode && (
						<FormGroup row>
							<Col xs={5}>
								<FormText color="muted" className="mb-1">
									Scan QR code using Authenticator App
								</FormText>
								<div className="mt-2">
									<QRCodeSVG
										fgColor={colours.savantSmelterCold}
										value={`otpauth://totp/AWSCognito:${user.username}?secret=${mfaCode}&issuer=AWSCognito`}
									/>
								</div>
							</Col>
							<Col xs={7}>
								<FormText color="muted" className="mb-1 ">
									Or enter this code into your Authenticator device
								</FormText>
								<div className="d-flex h-75">
									<Input
										type="textarea"
										name="mfaCode"
										id="mfaCode"
										bsSize="xs"
										value={mfaCode}
										readOnly
										className="text-monospace mt-1 mb-2 small"
										style={{ resize: 'none' }}
									/>

									{!this.state.copied && (
										<CopyToClipboard
											text={mfaCode}
											onCopy={() => this.setState({ copied: true })}
										>
											<div className="ml-2 align-self-center">
												<i className="fa fa-clone" />
											</div>
										</CopyToClipboard>
									)}
									{this.state.copied && (
										<div className="ml-2 align-self-center text-success">
											<i className="fa fa-check" />
										</div>
									)}
								</div>
							</Col>
						</FormGroup>
					)}
					<FormGroup row>
						<Col xs={12}>
							<Label for="totp">
								Enter the 6-digit code from your Authenticator:
							</Label>
							<Input
								id="totp"
								placeholder="Authentication code"
								autoFocus
								value={challengeCode}
								onChange={(e) =>
									this.setState({ challengeCode: e.target.value })
								}
							/>
						</Col>
					</FormGroup>
					{this.renderError()}
					<LoaderButton
						block
						color="primary"
						disabled={challengeCode.length !== 6}
						type="submit"
						isLoading={this.state.isVerifying}
						text="Verify"
						loadingText="Verifying…"
					/>
					<Mailto
						email="GAINSsupport@earthi.co.uk"
						subject="Lost MFA device"
						body="Please reset my MFA."
					>
						Lost MFA device?
					</Mailto>
				</Form>
			);
		}
	}

	render() {
		return (
			<DocumentTitle title="GAINS | Login">
				<BodyClassName className="bg-dark">
					<div className="container">
						<Card className="card-login mx-auto mt-5">
							<CardHeader className="card-logo-header">
								<ReactSVG
									src={earthiLogo}
									className="card-logo"
									svgClassName="earthi-card-logo"
								/>
								<ReactSVG
									src={gainsLogo}
									className="card-logo"
									svgClassName="gains-card-logo"
								/>
								<h1 className="sr-only">GAINS</h1>
							</CardHeader>
							<CardBody>
								{this.renderSignInForm()}
								{this.renderVerifyMfaForm()}

								<hr />
								<div className="mt-3 small">
									In association with:
									<br />
									<a
										href="https://www.esa.int/ESA"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											src={esaLogo}
											className="card-logo esa-card-logo pull-left"
											alt="European Space Agency"
										/>
									</a>
									<a
										href="https://navigatecommodities.com/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											src={navigateLogo}
											className="card-logo navigate-card-logo pull-right"
											alt="Navigate Commodities"
										/>
									</a>
									{/* <a
										href="http://www.marexspectron.com/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											src={marexSpectronLogo}
											className="card-logo marex-card-logo pull-right"
											alt="Marex Spectron"
										/>
									</a> */}
								</div>
							</CardBody>
						</Card>
						<Card className="card-login mx-auto mt-2">
							<CardBody>
								<div className="smaller">
									<strong>System access disclaimer:</strong> This is a
									restricted computer system which may be accessed and used only
									by authorised personnel. UNAUTHORISED ACCESS OR USE OF THIS
									COMPUTER SYSTEM IS STRICTLY PROHIBITED AND CONSTITUTES AN
									OFFENCE UNDER THE COMPUTER MISUSE ACT 1990 and may subject
									violators to criminal, civil, and/or administrative action.
									All access and use of this computer system is monitored and
									audited by authorised personnel, therefore there should not be
									any expectations of Right to Privacy on this computer system.
									All activity is subject to being intercepted, recorded, read,
									copied and disclosed by and to authorised personnel for
									official purposes, including criminal investigations. Access
									or use of this computer system by any person, whether
									authorised or unauthorised, constitutes consent to these
									terms.
								</div>
							</CardBody>
						</Card>
					</div>
				</BodyClassName>
			</DocumentTitle>
		);
	}
}
