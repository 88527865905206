import React, { useState } from 'react';

export const Mailto = ({ email, subject, body, children }) => {
	const [isHover, setHover] = useState(false);

	const handleMouseEnter = () => {
		setHover(true);
	};

	const handleMouseLeave = () => {
		setHover(false);
	};

	return (
		<a
			style={{
				textDecoration: isHover ? 'none' : 'blue',
				fontSize: '.9rem'
			}}
			href={`mailto:${email}?subject=${
				encodeURIComponent(subject) || ''
			}&body=${encodeURIComponent(body) || ''}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{children}
		</a>
	);
};
