import { combineReducers } from 'redux';
import { createFlagsReducer } from 'flag';
import { reducer as toastrReducer } from 'react-redux-toastr';

import flags from '../flags';
import mySmeltersReducer from './mySmeltersReducer';
import groupsReducer from './groupsReducer';
import locationsReducer from './locationsReducer';
import lastUploadDateReducer from './lastUploadDateReducer';
import steelPseudoGroupReducer from './steel/pseudoGroupReducer';
import steelSmelterAllMapDataReducer from './steel/smelterAllMapDataReducer';
import steelSmelterAllTimeBySmelterDataReducer from './steel/smelterAllTimeBySmelterDataReducer';
import steelSmelterDataFreeReducer from './steel/smelterDataFreeReducer';
import steelSmelterDataReducer from './steel/smelterDataReducer';
import steelSmelterCapacityReducer from './steel/smelterCapacityReducer';
import steelSmelterProductionReducer from './steel/smelterProductionReducer';
import steelSmelterProductionSubsitesReducer from './steel/smelterProductionSubsitesReducer';
import steelSmelterCO2EmissionsReducer from './steel/smelterCO2EmissionsReducer';
import steelSmelterListReducer from './steel/smelterListReducer';
import steelSmeltersStateReducer from './steel/smeltersStateReducer';
import steelSmelterRecentDataReducer from './steel/smelterRecentDataReducer';
import steelSmelterRecentUpdatesDataReducer from './steel/smelterRecentUpdatesDataReducer';
import steelSmelterRegionsReducer from './steel/smelterRegionsReducer';
import steelSmelterUpdatesDataReducer from './steel/smelterUpdatesDataReducer';
import steelSelectedSmeltersReducer from './steel/selectedSmeltersReducer';
import smelterShrinkAllTimeMapDataReducer from './smelterShrinkAllTimeMapDataReducer';
import stockpilesDataReducer from './stockpiles/stockpilesDataReducer';
import stockpilesDataBySiteReducer from './stockpiles/stockpilesDataBySiteReducer';
import scrapSteelDataReducer from './scrapSteel/scrapSteelDataReducer';
import scrapSteelDataBySiteReducer from './scrapSteel/scrapSteelDataBySiteReducer';
import sitesReducer from './sitesReducer';
import siteTypesReducer from './siteTypesReducer';

export default combineReducers({
	flags: createFlagsReducer(flags),
	mySmelters: mySmeltersReducer,
	groups: groupsReducer,
	locations: locationsReducer,
	sites: sitesReducer,
	siteTypes: siteTypesReducer,
	steelSelectedSmelters: steelSelectedSmeltersReducer,
	lastUploadDate: lastUploadDateReducer,
	steelPseudoGroups: steelPseudoGroupReducer,
	steelSmelterAllMapData: steelSmelterAllMapDataReducer,
	steelSmelterAllTimeBySmelter: steelSmelterAllTimeBySmelterDataReducer,
	steelSmelterData: steelSmelterDataReducer,
	steelSmelterCapacity: steelSmelterCapacityReducer,
	steelSmelterProduction: steelSmelterProductionReducer,
	steelSmelterProductionSubsites: steelSmelterProductionSubsitesReducer,
	steelSmelterCO2Emissions: steelSmelterCO2EmissionsReducer,
	steelSmelterDataFree: steelSmelterDataFreeReducer,
	steelSmeltersState: steelSmeltersStateReducer,
	steelSmelterList: steelSmelterListReducer,
	steelSmelterRecent: steelSmelterRecentDataReducer,
	steelSmelterRecentUpdates: steelSmelterRecentUpdatesDataReducer,
	steelSmelterRegions: steelSmelterRegionsReducer,
	steelSmelterUpdates: steelSmelterUpdatesDataReducer,
	smelterShrinkedAllTimeData: smelterShrinkAllTimeMapDataReducer,
	stockpilesData: stockpilesDataReducer,
	stockpilesDataBySite: stockpilesDataBySiteReducer,
	scrapSteelData: scrapSteelDataReducer,
	scrapSteelDataBySite: scrapSteelDataBySiteReducer,
	toastr: toastrReducer
});
