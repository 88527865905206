import { FETCH_SITE_TYPES } from '../actions';

export default (state = [], action) => {
	switch (action.type) {
		case FETCH_SITE_TYPES:
			const siteTypes = action.payload;
			return siteTypes;
		default:
			return state;
	}
};
