import { API } from 'aws-amplify';
// import axios from 'axios';
// import xml2js from 'xml2js';

import {
	FETCH_LAST_UPLOAD_DATE,
	FETCH_LAST_UPLOAD_DATE_ERR,
	FETCH_LOCATIONS,
	RESET_LOCATIONS,
	FETCH_LOCATIONS_ERR,
	FETCH_GROUPS,
	RESET_GROUPS,
	FETCH_GROUPS_ERR,
	FETCH_SITES,
	FETCH_SITES_ERR,
	FETCH_SITE_TYPES,
	FETCH_SITE_TYPES_ERR
} from './';

import config from '../config';
import { replacer } from '../utils';

// export const fetchEsriAttribution = async () => {
// 	try {
// 		const response = await axios({
// 			method: 'GET',
// 			url: 'https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/info/metadata'
// 		});
// 		const parser = new xml2js.Parser();
// 		const { metadata } = await parser.parseStringPromise(response.data);
// 		return metadata?.dataIdInfo[0]?.idCredit[0] || '';
// 	} catch (error) {
// 		console.error(error);
// 		return '';
// 	}
// };

export const fetchSiteGeoJson = async ({ id }) => {
	return await API.get(
		'gains-api',
		replacer(config.apiEndpoints.geoJsonData, {
			id
		}),
		{
			headers: {}
		}
	);
};

export const fetchLastUploadDate =
	({ commodity }) =>
	async (dispatch) => {
		const request = API.get(
			'gains-api',
			replacer(config.apiEndpoints.lastUploadDate, { commodity }),
			{
				headers: {}
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_LAST_UPLOAD_DATE,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_LAST_UPLOAD_DATE_ERR,
					payload: err
				})
		);
	};

export const resetLastUploadDate = () => ({
	type: FETCH_LAST_UPLOAD_DATE,
	payload: ''
});

export const fetchLocations =
	({ commodity = '', siteType = '' }) =>
	async (dispatch) => {
		const request = API.get(
			'gains-api',
			replacer(config.apiEndpoints.locations, { commodity, siteType }),
			{
				headers: {}
			}
		);
		return request.then(
			(response) => {
				return dispatch({
					type: FETCH_LOCATIONS,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_LOCATIONS_ERR,
					payload: err
				})
		);
	};

export const resetLocations = () => ({
	type: RESET_LOCATIONS,
	payload: ''
});

export const fetchGroups =
	({ commodity = '', siteType = '' }) =>
	async (dispatch) => {
		const request = API.get(
			'gains-api',
			replacer(config.apiEndpoints.groups, { commodity, siteType }),
			{
				headers: {}
			}
		);
		return request.then(
			(response) => {
				return dispatch({
					type: FETCH_GROUPS,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_GROUPS_ERR,
					payload: err
				})
		);
	};

export const resetGroups = () => ({
	type: RESET_GROUPS
});

export const fetchSites =
	({ commodity }) =>
	async (dispatch) => {
		const request = API.get(
			'gains-api',
			replacer(config.apiEndpoints.listSites, { commodity }),
			{
				headers: {}
			}
		);

		return request.then(
			(response) => {
				dispatch({
					type: FETCH_SITES,
					payload: response
				});
			},
			(err) =>
				dispatch({
					type: FETCH_SITES_ERR,
					payload: err
				})
		);
	};

export const resetSites = () => ({
	type: FETCH_SITES,
	payload: []
});

export const fetchSiteTypes = () => async (dispatch) => {
	const request = API.get(
		'gains-api',
		replacer(config.apiEndpoints.listSiteTypes, {}),
		{
			headers: {}
		}
	);
	return request.then(
		(response) => {
			return dispatch({
				type: FETCH_SITE_TYPES,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_SITE_TYPES_ERR,
				payload: err
			})
	);
};

export const resetSiteTypes = () => ({
	type: FETCH_SITE_TYPES,
	payload: []
});
