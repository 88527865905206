import { API } from 'aws-amplify';

import {
	getAuthHeaders,
	FETCH_MYSMELTERS,
	FETCH_MYSMELTERS_ERR,
	SHRINK_SMELTERS_ALL_TIME_MAP_DATA
} from './';
import config from '../config';
import { replacer } from '../utils';

export const fetchRegions = async () => {
	try {
		const response = await API.get('gains-api', config.apiEndpoints.regions, {
			headers: {}
		});
		return response;
	} catch (err) {
		console.log('Error getting smelter param list', err);
		return {};
	}
};

export const fetchMySmelters = () => async (dispatch) => {
	const request = API.get('savant-api', config.apiEndpoints.mySmelters, {
		headers: await getAuthHeaders()
	});

	return request.then(
		(response) => {
			dispatch({
				type: FETCH_MYSMELTERS,
				payload: response
			});
		},
		(err) =>
			dispatch({
				type: FETCH_MYSMELTERS_ERR,
				payload: err
			})
	);
};

export const resetMySmelters = () => (dispatch) => {
	dispatch({
		type: FETCH_MYSMELTERS,
		payload: []
	});
};

export const shrinkSmelterAllTimeMapData =
	({ smelterData }) =>
	(dispatch) => {
		const { capacity } = smelterData;
		const shrinkedData = {};
		Object.entries(capacity).forEach(([name, { data }]) => {
			shrinkedData[name] = !data.length
				? []
				: data.reduce(
						(result, item, idx) => {
							if (
								idx === data.length - 1 ||
								(idx !== 0 && data[idx].v !== data[idx - 1].v)
							) {
								result.push(item);
							}
							return result;
						},
						[data[0]]
				  );
		});

		dispatch({
			type: SHRINK_SMELTERS_ALL_TIME_MAP_DATA,
			payload: shrinkedData
		});
	};

export const resetShrinkedSmelterAllTimeMapData = () => ({
	type: SHRINK_SMELTERS_ALL_TIME_MAP_DATA,
	payload: {}
});

export const fetchSmelterParamList = async ({ smelterId, type }) => {
	try {
		const response = await API.get(
			'savant-api',
			replacer(config.apiEndpoints.paramListBySmelter, {
				smelterId,
				type
			}),
			{
				headers: await getAuthHeaders()
			}
		);
		return response;
	} catch (err) {
		console.log('Error getting smelter param list', err);
		return {};
	}
};
